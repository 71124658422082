import React, { Component } from "react";
import banner1 from "../../../assets/img/banner.webp";
import banner2 from "../../../assets/img/banner.webp";
import banner3 from "../../../assets/img/banner.webp";
import banner4 from "../../../assets/img/banner.webp";
import ClientsCarousel from "./Clients";
class Banner extends Component {
  render() {
    return (
      <div>
        <section className="section-hero hero">
          <div className="container hero__container">
            <div className="row">
              <div className="col-md-6">
                <div className="hero__text-content-wrap">
                  <div className="hero__text-content">
                    <h1 className="hero__title">Brilliant Minds.</h1>
                    <h1 className="hero__title">Intriguing Ideas </h1>
                    <h2 className="hero__subtitle">
                      We’re one of a kind digital agency that curates & enhances
                      brands to create a striking impression on our client’s
                      audience.
                    </h2>
                  </div>
                  <ClientsCarousel className="mt-32"/>
                </div>
              </div>
              <div className="col-md-6 d-md-block" id="container-img">
                <div className="hero__img">
                  <div className="hero__img-svg-container">
                    <svg
                      className="hero__img-svg"
                      viewBox="0 0 500 590"
                      preserveAspectRatio="xMinYMin meet"
                      data-image-flick-speed="600"
                    >
                      <image
                        href={banner1}
                        x="0"
                        y="0"
                        width="500"
                        height="590"
                      ></image>
                      <image
                        href={banner2}
                        x="0"
                        y="0"
                        width="500"
                        height="590"
                      ></image>
                      <image
                        href={banner3}
                        x="0"
                        y="0"
                        width="500"
                        height="590"
                      ></image>
                      <image
                        href={banner4}
                        x="0"
                        y="0"
                        width="500"
                        height="590"
                      ></image>
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-2">
                <Clientelle />
              </div> */}
            </div>
            <div className="row hero__bottom-row">
              <div className="col d-md-block d-none">
                <div className="hero__scroll-down local-scroll">
                  <span className="hero__scroll-down-link title-link animate-letters">
                    Scroll Down
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Banner;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import aivshuman from "../../../assets/img/blogs/ai-vs-human.webp";
import arvr from "../../../assets/img/blogs/ar-vr.webp";
import unlocking from "../../../assets/img/blogs/unlocking.webp";
import smm from "../../../assets/img/blogs/smm.webp";
import seo from "../../../assets/img/blogs/seo/seo-blog.jpg";

class Bloglists extends Component {
  render() {
    return (
      <div>
        <div className="project-grid row">
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/seo" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={seo}
                    className="project__img radius hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/seo">Why SEO Is Crucial for Your Website's Success</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/smm" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={smm}
                    className="project__img radius hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/smm">Strategy is Everything in Social Media Marketing</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/unlocking" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={unlocking}
                    className="project__img radius hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/unlocking">Unlocking Omnichannel Success</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/ai-vs-human" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={aivshuman}
                    className="project__img radius hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/ai-vs-human">
                      AI vs Human Creativity: Which is More Creative?
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/ar-vr" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={arvr}
                    className="project__img radius hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/ar-vr">
                      The Use of Augmented Reality (AR) and Virtual Reality (VR)
                      in the Creative Industry
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}
export default Bloglists;

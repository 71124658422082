import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import seo from "../../../assets/img/blogs/seo/seo-blog.jpg";
import smm from "../../../assets/img/blogs/smm.webp";

class Blog extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <section className="home-section-2 pb-10">
          <div className="container">
            <h1 className="project-description__title work-in-font-h1">
              Our Blogs
            </h1>
            <div className="project-grid row">
              <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
                <div className="project__container">
                  <Link to="/seo" className="project__url">
                    <figure className="project__img-holder hover-shrink--shrink">
                      <img
                        src={seo}
                        className="project__img hover-shrink--zoom"
                        alt="Marketing Agency in Coimbatore"
                      />
                    </figure>
                  </Link>
                  <div className="project__description-wrap">
                    <div className="project__description">
                      <h3 className="project__title">
                        <Link to="/seo">
                          Why SEO Is Crucial for Your Website's Success
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </article>
              <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
                <div className="project__container">
                  <Link to="/smm" className="project__url">
                    <figure className="project__img-holder hover-shrink--shrink">
                      <img
                        src={smm}
                        className="project__img hover-shrink--zoom"
                        alt="Marketing Agency in Coimbatore"
                      />
                    </figure>
                  </Link>
                  <div className="project__description-wrap">
                    <div className="project__description">
                      <h3 className="project__title">
                        <Link to="/smm">
                          Strategy is Everything in Social Media Marketing{" "}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </article>
              <div className="col-sm-12">
                <div className="grid-item project">
                  <Link
                    to="/blogs"
                    className="all-projects title-link underline animate-letters"
                  >
                    More Blogs
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Blog;

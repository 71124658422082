import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const Mailer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xagt7ws",
        "template_7lrcxzc",
        form.current,
        "IHtDNKlvmDL0zmuXi"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <h3 className="contact-form-heading">Let us know how we can help.</h3>

      <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="name"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            name="email"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Phone no"
            name="phone"
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            rows="3"
            placeholder="Enter your message"
            name="message"
          ></textarea>
        </div>
        <input type="Submit" value="Send" className="btn btn-default" />
      </form>
    </div>
  );
};
export default Mailer;

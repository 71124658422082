import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import logo from "../../assets/img/logo-w.svg";
import icon1 from "../../assets/img/icon-1.svg";
import icon2 from "../../assets/img/icon-2.svg";

import portfolioPdfFile from "../../../src/assets/pdf/Bluekode-Digital-Portfolio.pdf";
import pdfFile from "../../../src/assets/pdf/brochure.pdf";
import flyerFile from "../../../src/assets/pdf/Flyer.pdf";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer bg-dark">
          <div className="container">
            <div className="footer__cta">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="widget widget-cta text-center">
                    <h3 className="widget-cta__title mb-32">
                      Got a Project?
                      <Link to="/contact" className="widget-cta__link">
                        <span className="animate-letters">
                          Let's Get to Work
                        </span>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__widgets">
              <div className="row">
                <div className="col-lg-8 col-md-4">
                  <div className="widget widget-about-us">
                    <address className="widget-about-us__address">
                      No.33, E TV Swamy Rd, R.S. Puram, Coimbatore, Tamil Nadu
                      641002
                    </address>
                    <ExternalLink
                      href="mailto:hello@bluekodedigital.com"
                      className="widget-about-us__email"
                    >
                      hello@bluekodedigital.com
                    </ExternalLink>
                    <ExternalLink
                      href="tel:04224366443"
                      className="widget-about-us__phone"
                    >
                      0422-4366443
                    </ExternalLink>
                    <ExternalLink
                      href="tel:+916382215245"
                      className="widget-about-us__phone"
                    >
                      +91 6382 215245
                    </ExternalLink>

                    <ul className="footer-links">
                      <li>
                        <ExternalLink href="https://www.facebook.com/BluekodeDigitalAgency/">
                          <button className="facebook" name="facebook">
                            <span
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </ExternalLink>
                      </li>
                      <li>
                        <ExternalLink href=" https://www.instagram.com/bluekodedigital/">
                          <button className="instagram" name="instagram">
                            {" "}
                            <span
                              className="fa fa-instagram"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </ExternalLink>
                      </li>
                      <li>
                        <ExternalLink href="https://www.youtube.com/channel/UCvKu_yXdU9KSZz_X3rSAc0Q">
                          <button className="youtube" name="youtube">
                            <span
                              className="fa fa-youtube"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </ExternalLink>
                      </li>
                      <li>
                        <ExternalLink href="https://www.behance.net/bluekodedigital">
                          <button className="behance" name="behance">
                            <span
                              className="fa fa-behance"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </ExternalLink>
                      </li>
                      <li>
                        <ExternalLink href="https://www.linkedin.com/company/bluekode-digital">
                          <button className="linkedin" name="linkedin">
                            <span
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></span>
                          </button>
                        </ExternalLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="widget widget-nav-menu">
                    <ul>
                      <li>
                        <Link to="/" className="nav-link">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className="nav-link">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/works" className="nav-link">
                          Works
                        </Link>
                      </li>
                      <li>
                        <Link to="/service" className="nav-link">
                          Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/blogs" className="nav-link">
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <a href="https://bluekodeacademy.com/" rel="noreferrer" target="_blank" className="nav-link">
                          Courses
                        </a>
                      </li>
                      <li>
                        <Link to="/contact" className="nav-link">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4" id="pdf">
                  <div className="pdf-file">
                    <a href={portfolioPdfFile} target="_blank" rel="noreferrer">
                      <button className="pdf-btn">
                        Portfolio
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </button>
                    </a>
                    <a href={pdfFile} target="_blank" rel="noreferrer">
                      <button className="pdf-btn">
                        Brochure
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </button>
                    </a>

                    <a href={flyerFile} target="_blank" rel="noreferrer">
                      <button className="pdf-btn">
                        Flyer
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <Link to="/" className="logo__url">
                    <img
                      className="logo"
                      src={logo}
                      alt="Marketing Agency in Coimbatore"
                    />
                  </Link>
                </div>
                <div className="col-md-8">
                  <div className="widget widget-nav-menu">
                    <ul>
                      <li>
                        <ExternalLink href="https://bluekode.com">
                          © 2021 All Rights Reserved. Designed By 
                          Bluekode Solutions
                        </ExternalLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="footer-placeholder"></div>
        <div className="enquiry-now phone">
          <Link to="/contact">
            <img src={icon1} alt="" />
          </Link>
          <br />
          <ExternalLink href="https://wa.me/+916382215245">
            <img src={icon2} alt="web design companies" />
          </ExternalLink>
        </div>
      </div>
    );
  }
}
export default Footer;

import React, { Component } from "react";
import Services from "./layout/home/Services";
import { Helmet } from "react-helmet";
class Service extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Top Advertising agencies in Coimbatore | Digital Marketing Company
            in Coimbatore
          </title>
          <meta
            name="description"
            content="We are a full service advertising agency with the expertise to develop and deliver integrated campaigns that engage consumers and drive profitable, sustainable business results."
          />
          <meta
            name="keywords"
            content="online advertising companies in Coimbatore, online advertising companies,Top online advertising companies"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <Services />
          </main>
        </div>
      </div>
    );
  }
}
export default Service;

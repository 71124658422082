import React, { Component } from "react";
import { Link } from "react-router-dom";
import client1 from "../../../assets/img/kovaipazhamudirnilayam/01.webp";
import client2 from "../../../assets/img/seasonscafe/01.webp";
import client3 from "../../../assets/img/kpnsnacks/01.webp";
import client4 from "../../../assets/img/eauditoroffice/01.webp";

import client7 from "../../../assets/img/ourinfine/ourInfine.webp";
import client8 from "../../../assets/img/edureka/Edureka.webp";
class Worklist extends Component {
  render() {
    return (
      <div>
        <div className="project-grid row">
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/kovaipazhamudirnilayam" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client1}
                    className="project__img hover-shrink--zoom"
                    alt="Best Marketing Agencies in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/kovaipazhamudirnilayam">
                      Kovai Pazhamudir Nilayam
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured cutom-portfolio-2">
            <div className="project__container">
              <Link to="/seasonscafe" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client2}
                    className="project__img hover-shrink--zoom"
                    alt="Digital Marketing Agency"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/seasonscafe">Seasons Cafe</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured branding product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/kpnsnacks" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client3}
                    className="project__img hover-shrink--zoom"
                    alt="Digital Marketing Companies"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/kpnsnacks">KPN Snacks</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/eauditoroffice" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client4}
                    className="project__img hover-shrink--zoom"
                    alt="Corporate Films"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/eauditoroffice">E-Auditor Office</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/ourinfine" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client7}
                    className="project__img hover-shrink--zoom"
                    alt="Marketing Agency in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/ourinfine">Our Infine</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
          <article className="grid-item project hover-shrink featured product cutom-portfolio-2">
            <div className="project__container">
              <Link to="/edureka" className="project__url">
                <figure className="project__img-holder hover-shrink--shrink">
                  <img
                    src={client8}
                    className="project__img hover-shrink--zoom"
                    alt="Marketing Agency in Coimbatore"
                  />
                </figure>
              </Link>
              <div className="project__description-wrap">
                <div className="project__description">
                  <h3 className="project__title">
                    <Link to="/edureka">Edureka</Link>
                  </h3>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  }
}
export default Worklist;

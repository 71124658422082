import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import KPN from "../../../assets/img/logocarousel/kpn.webp";
import IBHOME from "../../../assets/img/logocarousel/ibh.webp";
import SEASONS from "../../../assets/img/logocarousel/sea.webp";
import AKSHARA from "../../../assets/img/logocarousel/aks.webp";
import SVPS from "../../../assets/img/logocarousel/svps.webp";
import VENDI from "../../../assets/img/logocarousel/vd.webp";
import KOVAISNACKS from "../../../assets/img/logocarousel/ks.webp";
import HESSTIALS from "../../../assets/img/logocarousel/he.webp";
import SWV from "../../../assets/img/logocarousel/swv.webp";
import HINSPIRED from "../../../assets/img/logocarousel/hi.webp";

export default function ClientsCarousel() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <div className="col client-carousel-custom">
      {/* <section className="home-section-2 pb-10"> */}
        <div className="">
          <h1 className="project-description__title work-in-font-h1 hlc__head custom--home-head">
            Our Clients
          </h1>
          </div>
          {/* <div className="hlc__container"> */}
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5}
        transitionDuration={5000}
        centerMode={false}
        slidesToSlide={1}
        shouldResetAutoplay
        containerclassName="hlc__container"
        removeArrowOnDeviceType={[
          "tablet",
          "mobile",
          "desktop",
          "superLargeDesktop",
        ]}
        itemclassName="carousel-item-padding-40-px"
        customTransition="all 5s linear"
      >
        <div className="hlc__image">
          <img src={KPN} alt="Kovai Pazhamudir Nilayam" />
        </div>
        <div className="hlc__image">
          <img src={IBHOME} alt="IBO Wholesale" />
        </div>
        <div className="hlc__image">
          <img src={SEASONS} alt="Seasons Cafe" />
        </div>
        <div className="hlc__image">
          <img src={AKSHARA} alt="Akshara" />
        </div>
        <div className="hlc__image">
          <img src={SVPS} alt="Sri Vivekanandha Public School" />
        </div>
        <div className="hlc__image">
          <img src={VENDI} alt="VENDI Silver" />
        </div>
        <div className="hlc__image">
          <img src={KOVAISNACKS} alt="Kovai Snacks" />
        </div>

        <div className="hlc__image">
          <img src={HESSTIALS} alt="Heritage Essentials" />
        </div>
        <div className="hlc__image">
          <img src={SWV} alt="SWV Metals" />
        </div>
        <div className="hlc__image">
          <img src={HINSPIRED} alt="Heritage Inspired" />
        </div>
      </Carousel>
      {/* </div>
        </div>
      </section> */}
    </div>
  );
}

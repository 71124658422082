import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logo from "../../assets/img/logo-b.svg";
import loading from "../../assets/img/logo-w.svg";

class Navbar extends Component {
  render() {
    return (
      <div>
        <div className="loader-mask">
          <div className="loader">
            <img
              className="loader-logo logo"
              src={loading}
              alt="Advertising Agency in Coimbatore"
            />
            <div className="loader-bar">
              <span></span>
            </div>
            "Loading..."
          </div>
        </div>
        <header className="nav header">
          <div className="nav__holder nav--sticky" id="nav--sticky">
            <div className="container nav__container">
              <div className="flex-parent">
                <div className="nav__header clearfix">
                  <div className="logo-wrap local-scroll">
                    <Link to="/" className="logo__url">
                      <img
                        className="logo logo--dark"
                        src={logo}
                        alt="Digital Marketing Company"
                      />
                    </Link>
                  </div>
                  <button
                    type="button"
                    className="nav__icon-toggle"
                    id="nav__icon-toggle"
                    data-toggle="collapse"
                    data-target="#navbar-collapse"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="nav__icon-toggle-bar"></span>
                    <span className="nav__icon-toggle-bar"></span>
                    <span className="nav__icon-toggle-bar"></span>
                  </button>
                </div>
                <nav
                  id="navbar-collapse"
                  className="nav__wrap collapse navbar-collapse"
                >
                  <ul className="nav__menu local-scroll" id="onepage-nav">
                    <li>
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" className="nav-link">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/works" className="nav-link">
                        Works
                      </Link>
                    </li>
                    <li className="nav-link dropdown">
                      <Link to="/service" className="nav-link">
                        Services
                      </Link>
                    </li>
                    <li className="nav-link dropdown">
                      <Link to="#" className="nav-link">Pricing</Link>
                      <i
                        className="fa fa-angle-down nav-i"
                        aria-hidden="true"
                      ></i>
                      <ul className="nav__menu__dropdown nav__menu local-scroll">
                        <li>
                          <Link to="/branding" className="nav-link">
                            Branding
                          </Link>
                        </li>
                        <li className="nav-link dropdown-inner">
                          <Link to="#" className="nav-link">Advertising</Link>
                          <i
                            className="fa fa-angle-down nav-i-ad"
                            aria-hidden="true"
                          ></i>

                          <ul className="nav__menu__dropdown__inner nav__menu local-scroll">
                            <li>
                              <Link
                                to="/socialmediamarketting"
                                className="nav-link"
                              >
                                SMM
                              </Link>
                            </li>
                            <li>
                              <Link to="/seo-and-content" className="nav-link">
                                SEO & Content
                              </Link>
                            </li>
                            <li>
                              <Link to="/paid-ads" className="nav-link">
                                Paid Ads
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/production" className="nav-link">
                            Production
                          </Link>
                        </li>

                        <li className="nav-link dropdown-inner-two">
                          <Link to="#" className="nav-link">UI & UX </Link>
                          <i
                            className="fa fa-angle-down nav-i-ad"
                            aria-hidden="true"
                          ></i>

                          <ul className="nav__menu__dropdown__inner_two nav__menu local-scroll">
                            <li>
                              <Link to="/corporate" className="nav-link">
                                Corporate
                              </Link>
                            </li>
                            <li>
                              <Link to="/ecommerce" className="nav-link">
                                E-Commerce
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/contact" className="nav-link">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
export default Navbar;

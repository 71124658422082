import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/partial/Navbar";
import Footer from "./components/partial/Footer";
import Works from "./components/Works";
import Service from "./components/Service";
import Contact from "./components/Contact";
import Kovaipazhamudirnilayam from "./components/Kovaipazhamudirnilayam";
import Seasonscafe from "./components/Seasonscafe";
import Kpnsnacks from "./components/Kpnsnacks";
import Eauditoroffice from "./components/Eauditoroffice";
import About from "./components/About";
import Blogs from "./components/Blogs";
import Branding from "./components/layout/pricing/Branding";
import Socialmediamarketting from "./components/layout/pricing/Socialmediamarketting";
import Ourinfine from "./components/Ourinfine";
import Edureka from "./components/edureka";
import Seoandcontent from "./components/layout/pricing/Seoandcontent";
import Paidads from "./components/layout/pricing/Paidads";
import Production from "./components/layout/pricing/Production";
import Corporate from "./components/layout/pricing/Corporate";
import Ecommerce from "./components/layout/pricing/Eommerce";
import Aivshuman from "./components/layout/blog-pages/Aivshuman";
import Arvr from "./components/layout/blog-pages/Arvr";
import Unlocking from "./components/layout/blog-pages/Unlocking";
import SocialMediaMarketing from "./components/layout/blog-pages/Smm";
import SEO from "./components/layout/blog-pages/seo";

function App() {
  return (
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/works" component={Works} />
          <Route path="/service" component={Service} />
          <Route path="/branding" component={Branding} />
          <Route
            path="/socialmediamarketting"
            component={Socialmediamarketting}
          />
          <Route path="/seo-and-content" component={Seoandcontent} />
          <Route path="/paid-ads" component={Paidads} />
          <Route path="/production" component={Production} />
          <Route path="/corporate" component={Corporate} />
          <Route path="/ecommerce" component={Ecommerce} />
          <Route path="/contact" component={Contact} />
          <Route path="/blogs" component={Blogs} />
          <Route
            path="/kovaipazhamudirnilayam"
            component={Kovaipazhamudirnilayam}
          />
          <Route path="/seasonscafe" component={Seasonscafe} />
          <Route path="/kpnsnacks" component={Kpnsnacks} />
          <Route path="/eauditoroffice" component={Eauditoroffice} />
          <Route path="/ourinfine" component={Ourinfine} />
          <Route path="/edureka" component={Edureka} />
          <Route path="/ai-vs-human" component={Aivshuman} />
          <Route path="/ar-vr" component={Arvr} />
          <Route path="/unlocking" component={Unlocking} />
          <Route path="/smm" component={SocialMediaMarketing} />
          <Route path="/seo" component={SEO} />
        </Switch>
        <Footer />
      </Router>
  );
}
export default App;

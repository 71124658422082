import React, { Component } from "react";
import patner from "../../../assets/img/zoho.svg";

class Team extends Component {
  render() {
    return (
      <div>
        <section className="team-section patner-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h6 className="panter-cont">
                  Joining hands with Zoho Commerce to modernize & redefine
                  businesses
                </h6>
                <img className="panter-logo" src={patner} alt="Zoho" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Team;

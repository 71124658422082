import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";

function Corporate() {
  const [annually] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          Website Design & Development Packages in India
                        </h2>
                        <p>
                          Our website packages are designed to help you set up
                          your online presence. With a variety of options and
                          features, you can create your perfect website suite
                          that meets your business needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                30000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Upto 3 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Static Website
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      10GB SSD Storage
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      5000 Visits Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Banner Design
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      jQuery Slider
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Contact/Query Form
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social Profile Link
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                40000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                3-5 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Static Website
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      4 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      15GB SSD Storage
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      10000 Visits Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Banner Design
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      jQuery Slider
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Contact/Query Form
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social Profile Link
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Gallery Section
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat Feature
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                50000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                5 - 7 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Static Website
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      6 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      20GB SSD Storage
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      15000 Visits Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Banner Design
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      jQuery Slider
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Custom Form
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social Profile Link
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Gallery Section
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat Feature
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Lead Capturing Forms (Optional)
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Hover effects
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="Print-media">
                <div className="container">
                  <div className="row  pzero margin-b pricing-mx-auto">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table-below " id="pricing">
                        <div className="price-header-below box-print">
                          <h3 className="price-title">
                            Custom React Js Website
                          </h3>
                          <p>from</p>
                          <h4 className="dol-h4">
                            <span className="dollar">
                              <span className="">&#8377;</span>{" "}
                            </span>
                            60000
                          </h4>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h3 className="title mt-30">
                          WEBSITE MAINTENANCE - YEARLY
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                12000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Updates (Photos, Navigation, Links)
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Image Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Code Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Speed Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Adding and Updating New Content
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Health Check
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken Link Check & Fix
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-20"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-20"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                15000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Updates (Photos, Navigation, Links)
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Image Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Code Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Speed Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Adding and Updating New Content
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Health Check
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken Link Check & Fix
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-21"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-21"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Additional Page - 1
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      New Product / Service Upload
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Domain & Hosting Assistance
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                20000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Updates (Photos, Navigation, Links)
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Image Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Code Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Speed Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Adding and Updating New Content
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website Health Check
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken Link Check & Fix
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-22"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-22"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Additional Page - 2
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      New Product / Service Upload
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Domain & Hosting Assistance
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="container text-center">
              <p>
                Please note that GST will be charged in addition to the prices
                mentioned.
              </p>
            </div>
            <div className="container faq">
              <div className="row">
                <div className="col-md-12">
                  <h2>FAQ</h2>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer">
                      What is a dynamic Website? What are its advantages ?{" "}
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Dynamic websites are websites with database
                          connectivity and are also interactive in the sense
                          that they are interactive. They provide the website
                          owner with the capability to make changes to the
                          website without requiring any technical know-how. They
                          have many advantages and are also expensive.
                        </p>
                        <ul>
                          <li>
                            <strong>Advantages</strong>
                            <ul>
                              <li>
                                It is much more interactive and provides a great
                                user experience.
                              </li>
                              <li>
                                Easy to add and update content on the site
                                through CMS integration.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-2"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                      What is a static website?What are its advantages and
                      disadvantages?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          They are the most informative websites designed for
                          new businesses or businesses in which the nature of
                          business does not require business owners to create a
                          functional website. They are extremely simple and need
                          greater technical knowledge to update them.
                        </p>
                        <ul>
                          <li>
                            <strong>Advantages</strong>
                            <p>
                              Static websites aren't too expensive, and they
                              take less time to build them. A static website
                              hosting service is also affordable.
                            </p>
                          </li>
                          <li>
                            <strong>Disadvantages</strong>
                            <p>
                              Web admins should be aware of HTML/CSS/Photoshop.
                              to keep their websites up to date or employ an
                              agency to design their websites to manage their
                              site.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-3"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                      Can you handle ongoing maintenance?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Yes! If we created your website, we'd be delighted to
                          manage maintenance. We'll be the only party aware of
                          how your site operates and will be aware of updates
                          and patches to ensure that your site is up to date.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-4"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                      Does my site need to be updated to HTTPS instead of HTTP?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          In 2018, there's an excuse - or reason not to make any
                          site HTTPS compatible by purchasing the SSL
                          certificate. Beginning in August, the most well-known
                          internet browser, Google Chrome, will warn users in a
                          bold red color if their website isn't secure enough.
                          Firefox has already started this in its browser, along
                          with Microsoft's Edge and Apple's Safari will surely
                          follow. Make sure to get your SSL certificate and
                          ensure that it is up-to-date. This will prevent your
                          website from being altered secretly and your
                          customer's data from being watched or stolen.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-5"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                      Can you handle ongoing maintenance?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Yes! If we created your website, we'd be delighted to
                          manage maintenance. We'll be the only party acquainted
                          with how your site operates and will stay up-to-date
                          with the latest updates and patches to keep the site
                          running smoothly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-6"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                      What kind of businesses do you work with?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          We have worked with a wide variety of companies small
                          start-ups and large corporations, non-profits, B2B,
                          B2C, and more in a wide range of business sectors
                          technology, food apparel, health and beauty camp,
                          travel arts, fair finance trade, and much more. It is
                          a great opportunity to work with fair trade and fair
                          trade companies.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Corporate;

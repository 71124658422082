import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";

function Socialmediamarketting() {
  const [modalShow, setModalShow] = React.useState(false);

  const [annually, setAnnually] = useState(false);
  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          Social Media Marketing Packages in India
                        </h2>
                        <p>
                          We offer a wide range of SMM services that improve
                          your social media presence and help you get the most
                          out of your business. Check out our packages below!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="toggle lg:mb-10">
                    <label className="name">Monthly</label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked={annually}
                      onChange={() => setAnnually(!annually)}
                    />
                    <label htmlFor="checkbox" className="label">
                      <div className="ball"></div>
                    </label>
                    <label className="name">Quarterly</label>
                    <div id="badge">
                      <h6 className="vc">
                        Offer upto <span className="">&#8377;</span>30,000
                      </h6>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                30000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                90000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                80000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Google My Business
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Creation / Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                GMB Posting
                                <span className="blue">15 Post / month</span>
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Local Business listing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Indiamart
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Just dial
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Social Media Page Creation
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Instagram
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Youtube
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Account Management
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Publishing or Scheduling
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Extensive Hashtag Research
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Creative Copywriting
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Creatives
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Posters
                                      <span className="blue">
                                        2 Posters/week
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly Reports
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Reports
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Ads Management *
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Additional Charges
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                50000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut proff lkw">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                150000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                130000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div
                          className="price-list pzero table-section"
                          style={{ marginTop: "1em" }}
                        >
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Google My Business
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Creation / Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                GMB Posting<span>&nbsp; 20 Post / month</span>
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Local Business listing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Indiamart
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Just dial
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sulekha
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yellow images
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Social Media Page Creation
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Instagram
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Youtube
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Account Management
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Publishing or Scheduling
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Extensive Hashtag Research
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Creative Copywriting
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      8 Hours of Engagement
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Keyword Research/Tagging Research
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Creatives
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Posters
                                      <span>&nbsp; 1 Posters / week</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Reels/Shorts (Video)
                                      <span>&nbsp; 1 Reel / week</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly Reports
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Reports
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Ads Management *
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Additional Charges
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                70000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut lkw">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                210000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                180000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div
                          className="price-list pzero table-section"
                          style={{ marginTop: "1em" }}
                        >
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Google My Business
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Creation / Optimization
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                GMB Posting
                                <span className="blue">30 Post / month</span>
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Local Business listing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Indiamart
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Just dial
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sulekha
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yellow images
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Social Media Page Creation
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Instagram
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Youtube
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Twitter
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Account Management
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Publishing or Scheduling
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Extensive Hashtag Research
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Creative Copywriting
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      8 Hours of Engagement
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Keyword Research/Tagging Research
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Creatives
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Posters
                                      <span className="blue">
                                        2 Posters / week
                                      </span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Reels/Shorts (Video)
                                      <span className="blue">
                                        1 Reel / week
                                      </span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      GIF
                                      <span className="blue">1 GIF / week</span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly Reports
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Reports
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Ads Management *
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Additional Charges
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="Print-media">
              <div className="container">
                <div className="row  pzero margin-b">
                  <div className="col-lg-3 col-md-12"></div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Flyer</h3>

                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          3000
                        </h4>
                      </div>
                      <div className="faq-drawer for-mob">
                        <input
                          className="faq-drawer__trigger for-mobile-trigger"
                          id="faq-drawer-16"
                          type="checkbox"
                        />
                        <label
                          className="faq-drawer__title for-mobile-trigger"
                          htmlFor="faq-drawer-16"
                        >
                          Show Features
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <div className="first-box">
                              <ul className="list-unstyled text-left">
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  A4 or A5
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Web & Print Version
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Leaflet or Brochure</h3>
                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          5000
                        </h4>
                      </div>
                      <div className="faq-drawer for-mob">
                        <input
                          className="faq-drawer__trigger for-mobile-trigger"
                          id="faq-drawer-17"
                          type="checkbox"
                        />
                        <label
                          className="faq-drawer__title for-mobile-trigger"
                          htmlFor="faq-drawer-17"
                        >
                          Show Features
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <div className="first-box">
                              <ul className="list-unstyled text-left">
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  A4 or A5
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  upto 8 pages
                                </li>
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Web & Print Version
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12"></div>
                </div>
                <div className="row pzero justify-content-center">
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Outdoor Advertising</h3>
                        {/* <br /> */}

                        <p>from</p>

                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          3000
                        </h4>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => setModalShow(true)}
                        className="btn btn-theme my-4"
                        data-text="Get Started"
                      >
                        <span>G</span>
                        <span>e</span>
                        <span>t</span>
                        <span>&nbsp;</span>
                        <span>S</span>
                        <span>t</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>e</span>
                        <span>d</span>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">
                          Newspaper or Magazine Ads
                        </h3>
                        {/* <br/> */}
                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          3000
                        </h4>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => setModalShow(true)}
                        className="btn btn-theme my-4"
                        data-text="Get Started"
                      >
                        <span>G</span>
                        <span>e</span>
                        <span>t</span>
                        <span>&nbsp;</span>
                        <span>S</span>
                        <span>t</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>e</span>
                        <span>d</span>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Packaging Design</h3>
                        {/* <br /> */}
                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          5000
                        </h4>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => setModalShow(true)}
                        className="btn btn-theme my-4"
                        data-text="Get Started"
                      >
                        <span>G</span>
                        <span>e</span>
                        <span>t</span>
                        <span>&nbsp;</span>
                        <span>S</span>
                        <span>t</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>e</span>
                        <span>d</span>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-center mt-60">
                <p>
                  Please note that GST will be charged in addition to the prices
                  mentioned.
                </p>
              </div>
            </section>
            <div className="container faq">
              <div className="row">
                <div className="col-md-12">
                  <h2>FAQ</h2>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer">
                      What metrics should I measure in social media?{" "}
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Your business's metrics to gauge your business's
                          success or identify opportunities should be determined
                          by your company's goals.
                        </p>
                        <ul>
                          <li>
                            <strong>Impressions :</strong> Are you increasing
                            the number of people who can see your company's name
                            through social networks?
                          </li>
                          <li>
                            <strong>Engaged :</strong> Is anyone taking part in
                            the discussion or sharing posts?
                          </li>
                          <li>
                            <strong>The frequency of mentions :</strong> How
                            often are people talking about your brand?
                          </li>
                          <li>
                            <strong>Social Referrals Traffic :</strong> How many
                            visitors visit your site?
                          </li>
                          <li>
                            <strong>Followers :</strong> How many are on your
                            social networks?
                          </li>
                        </ul>
                        <p>
                          To get the most accurate view of how social media can
                          benefit your business, look at social media metrics in
                          conjunction with other channels.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-2"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                      What social platforms should I be using?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It's easy to become lost in the hype of emerging
                          social media channels. So start by selecting one or
                          two that make the most sense. Then, take advantage of
                          these channels by regularly posting high-quality
                          content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-3"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                      How can I increase my followers?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          If your social media accounts are well-managed, they
                          will see growth ways to targeted ads or boosts with
                          carefully written text, highly shareable content,
                          appealing images, and platform-specific best
                          practices.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-4"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                      How effective is a video on social media?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Very. Your social media strategy for your company must
                          incorporate video, especially for platforms like
                          Instagram or YouTube. Every major social media
                          platform can accommodate video assets, and 81 % of
                          businesses utilize video marketing to improve sales
                          and brand recognition.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-5"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                      What are some common issues with social media marketing
                      plans?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Many companies don't ensure their content is tailored
                          for every platform. While cross-posting to Facebook
                          and Instagram might be an option, we recommend, at a
                          minimum, if not the most, making your post's
                          descriptions more optimized for each platform.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-6"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                      How has social media changed marketing strategies?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Social media has become an integral component of a
                          well-designed <strong>online marketing</strong> plan.
                          Many companies use social media for marketing at the
                          top of their funnel and then use paid advertisements
                          on social media to fuel bottom-of-the-funnel or
                          middle-of-the-fill.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-7"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                      Why should I invest in social media marketing?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The best part is that social media can greatly
                          increase brand recognition and create a community of
                          potential customers. In the end, it can be a
                          significant source of revenue. It's an instrument for
                          facilitating conversations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-8"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-8">
                      How much does social media marketing cost?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Our social media marketing plans are designed to help
                          you build a following and grow your business. Let us
                          know how we can help!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
export default Socialmediamarketting;
<div className="main-wrap">
  <main className="content-wrap">
    <section className="section-works work-page">
      <div className="container"></div>
    </section>
  </main>
</div>;

import React, { Component } from "react";

import Bloglists from "./layout/blogslists/Bloglists";

class Blogs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <Bloglists />
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Blogs;

import React, { Component } from "react";
import Iframe from "react-iframe";
import { ExternalLink } from "react-external-link";
import Mailer from "./layout/form/Mailer";
import { Helmet } from "react-helmet";
class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Brand Promotions;Best Advertising Agency;Digital Marketing Services
          </title>
          <meta
            name="description"
            content="We build digital marketing and advertising campaigns for brands. Our specialty is in 360-degree approach, integrating several digital channels, providing a whole picture for our clients."
          />
          <meta
            name="keywords"
            content="top advertising agencies in coimbatore,Digital Marketing companies in coimbatore"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap contact-page-main">
            <section className="contact-page-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="page-title__holder">
                      <h1 className="page-title__title">
                        We would love to become your Digital Partner.
                      </h1>
                      <p className="page-title__subtitle">
                        Let’s talk about your next project today!
                      </p>
                    </div>
                    <div className="row contact-details-dection">
                      <div className="cutom-contact-2">
                        <div className="contact-info">
                          <h3 className="contact-info__title">Contact</h3>
                          <ExternalLink
                            href="mailto:hello@bluekodedigital.com"
                            className="contact-info__email"
                          >
                            hello@bluekodedigital.com
                          </ExternalLink>
                          <ExternalLink
                            href="tel:04224366443"
                            className="contact-info__phone"
                          >
                            0422-4366443
                          </ExternalLink>
                          <ExternalLink
                            href="tel:+916382215245"
                            className="contact-info__phone"
                          >
                            +91 6382 215245
                          </ExternalLink>
                          <ul className="footer-links footer-links-wight">
                            <li>
                              <ExternalLink href="https://www.facebook.com/BluekodeDigitalAgency/">
                                <button className="facebook">
                                  <span
                                    className="fa fa-facebook"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </ExternalLink>
                            </li>
                            <li>
                              <ExternalLink href=" https://www.instagram.com/bluekodedigital/">
                                <button className="instagram">
                                  {" "}
                                  <span
                                    className="fa fa-instagram"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </ExternalLink>
                            </li>
                            <li>
                              <ExternalLink href="https://www.youtube.com/channel/UCvKu_yXdU9KSZz_X3rSAc0Q">
                                <button className="youtube">
                                  <span
                                    className="fa fa-youtube"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </ExternalLink>
                            </li>
                            <li>
                              <ExternalLink href="https://www.behance.net/bluekodedigital">
                                <button className="linkedin">
                                  <span
                                    className="fa fa-behance"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </ExternalLink>
                            </li>
                            <li>
                              <ExternalLink href="https://www.linkedin.com/company/bluekode-digital">
                                <button className="linkedin">
                                  <span
                                    className="fa fa-linkedin"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </ExternalLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="cutom-contact-2">
                        <div className="contact-info">
                          <h3 className="contact-info__title">Address</h3>
                          <address className="contact-info__address">
                            No.33, E TV Swamy Rd, R.S. Puram, Coimbatore, Tamil
                            Nadu 641002
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <Mailer />
                  </div>
                </div>
              </div>
            </section>
            <section className="section-contact-info pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Iframe
                      url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.377870308755!2d76.94984031524184!3d11.010249607843829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859a064c8833b%3A0xbcfbd5f98a3650e8!2sBluekode%20Digital%20%7C%20Best%20Advertising%20Agency%20in%20Coimbatore%20%7C%20Social%20Media%20Marketing%20Agency%20%7C%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2sin!4v1646739031664!5m2!1sen!2sin"
                      width="100%"
                      height="300px"
                      className="boder-0"
                      display="initial"
                      position="relative"
                    />
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Contact;

import React, { useState } from "react";
import team1 from "../../../assets/img/team/Senthil_Raj.webp";
import team2 from "../../../assets/img/team/Shyam_Benny.webp";
import team3 from "../../../assets/img/team/Pradeepan.webp";
import team4 from "../../../assets/img/team/Keerthana.webp";
import team5 from "../../../assets/img/team/Nyndhru_Shree.webp";
import team6 from "../../../assets/img/team/Keerthy_Varman.webp";
import team7 from "../../../assets/img/team/Kishor_Kumar.webp";
import team8 from "../../../assets/img/team/Pradeep.webp";
import team9 from "../../../assets/img/team/Prasath.webp";

function Team() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };

  return (
    <div>
      <section className="team-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="project-description__title work-in-font-h1">
                Meet the Team
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="team-col-3 col-sm-12">
              <div className="our-team">
                <img
                  onClick={handleClick}
                  src={team1}
                  className=""
                  alt="Digital Marketing Company"
                />
                <h4>Senthil Raj</h4>
                <p>Director - HR | Marketing</p>
                <div className="">
                  {isShown && (
                    <div className="anim">
                      <div className="containerddesk icons-all">
                        <i className="fa fa-facebook-f trsns"></i>
                        <i className="fa fa-twitter trsns"></i>
                        <i className="fa fa-instagram trsns"></i>
                        <i className="fa fa-github trsns"></i>
                      </div>
                      <div className="containerddesk">
                        <p>
                          he is hr and marketting manager and marketting manager
                          and marketting manager and marketting manager and
                          marketting manager
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="team-col-3 col-sm-12">
              <div className="our-team">
                <img
                  src={team2}
                  className=""
                  alt="Digital Marketing Services"
                />
                <h4>Shyam Benny</h4>
                <p>Creative Head</p>
              </div>
            </div>

            <div className="team-col-3 col-sm-12">
              <div className="our-team">
                <img
                  src={team3}
                  className=""
                  alt="Marketing Agency in Coimbatore"
                />
                <h4>Pradeepan</h4>
                <p>Sr. Motion Graphic Designer</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team4} className="" alt="Digital Marketing Agency" />
                <h4>Keerthana</h4>
                <p>E-Com Implementation Lead</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team5} className="" alt="Digital Marketing Agency" />
                <h4>Nyndhrushree</h4>
                <p>Content Writer</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team6} className="" alt="Digital Marketing Agency" />
                <h4>Keerthy Varman</h4>
                <p>Sr. Graphic Designer</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team7} className="" alt="Digital Marketing Agency" />
                <h4>Kishor Kumar</h4>
                <p>Jr. Graphic Designer</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team8} className="" alt="Digital Marketing Agency" />
                <h4>Pradeep</h4>
                <p>Digital Marketing Analyst</p>
              </div>
            </div>
            <div className="team-col-3">
              <div className="our-team">
                <img src={team9} className="" alt="Digital Marketing Agency" />
                <h4>Prasath</h4>
                <p>Jr. UI Developer</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;

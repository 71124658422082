import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";
function Seoandcontent() {
  const [modalShow, setModalShow] = React.useState(false);
  const [annually, setAnnually] = useState(false);
  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          SEO & Content Marketing Packages in India
                        </h2>
                        <p>
                          Of all people who do a google search for any service
                          or brand on the internet, 85% of them will visit or
                          call the business within a day.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="toggle lg:mb-10">
                    <label className="name">Monthly</label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked={annually}
                      onChange={() => setAnnually(!annually)}
                    />
                    <label htmlFor="checkbox" className="label">
                      <div className="ball"></div>
                    </label>
                    <label className="name">Quarterly</label>
                    <div id="badge">
                      <h6 className="vc">
                        Offer upto <span className="">&#8377;</span>20,000
                      </h6>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                15000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                45000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                40000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Website Review & Analysis
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No.of Keywords
                                <span className="blue">5 Keywords</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Keyword research and analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Duplicate content analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken links and page size check
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      On-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Title tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Meta tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL canonicalization check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Robots.txt optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Alt tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Anchor text optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      W3 validation check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Existing content optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Website structure analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL mapping and rewriting
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      New page creation
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Content Marketing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog Writing
                                      <span className="blue">
                                        500 – 700 words – 1 Blog
                                      </span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article Writing
                                      <span className="blue">
                                        500 – 700 words – 1 Blog
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Off-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social bookmarking
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article submission
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog commenting
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image submission
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Business listing
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog directory submission
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Document & PDF submission
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor backlinks
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Broken link building
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Monthly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Search engine ranking report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google analytics report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      SEO Activity report
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Yearly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Search engine ranking reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google My Business yearly report
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Google Analytics reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Project Deck
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                25000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut proff ">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                75000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                65000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Website Review & Analysis
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No.of Keywords<span>&nbsp;15 Keywords</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Keyword research and analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Duplicate content analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken links and page size check
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      On-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Title tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Meta tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL canonicalization check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Robots.txt optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Alt tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Anchor text optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      W3 validation check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Existing content optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Website structure analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL mapping and rewriting
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      New page creation
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Content Marketing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog Writing
                                      <span>
                                        &nbsp;500 – 700 words – 2 Blog
                                      </span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article Writing
                                      <span>
                                        &nbsp;500 – 700 words – 2 Blog
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Off-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social bookmarking<span>&nbsp;20</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article submission<span>&nbsp;10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog commenting<span>&nbsp;20</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image submission<span>&nbsp;20</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Business listing<span>&nbsp;10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog directory submission
                                      <span>&nbsp;20</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Document & PDF submission
                                      <span>&nbsp;10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor backlinks
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Broken link building
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Monthly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Search engine ranking report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google analytics report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      SEO Activity report
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Yearly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Search engine ranking reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google My Business yearly report
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Google Analytics reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Project Deck
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                40000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut lkw">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                120000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                100000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Website Review & Analysis
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No.of Keywords
                                <span className="blue">25 Keywords</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Website audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Keyword research and analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Duplicate content analysis
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Competitor audit
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Broken links and page size check
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      On-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Title tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Meta tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL canonicalization check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Robots.txt optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Alt tag optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Anchor text optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      W3 validation check
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Existing content optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Website structure analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      URL mapping and rewriting
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      New page creation
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Content Marketing
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog Writing
                                      <span className="blue">
                                        500 – 700 words – 1 Blog
                                      </span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article Writing
                                      <span className="blue">
                                        500 – 700 words – 1 Blog
                                      </span>
                                    </li>
                                  </ul>
                                </div>

                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Off-Page SEO Optimization
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Social bookmarking
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Article submission
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog commenting
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Image submission
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Business listing
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Blog directory submission
                                      <span className="blue">10</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Document & PDF submission
                                      <span className="blue">5</span>
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor backlinks
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Broken link building
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Monthly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Search engine ranking report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google analytics report
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      SEO Activity report
                                    </li>
                                  </ul>
                                </div>
                                <div className="sixth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Yearly Project Reporting
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Search engine ranking reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google My Business yearly report
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Google Analytics reports
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Annual Project Deck
                                    </li>
                                  </ul>
                                </div>
                                <div className="seventh-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container text-center">
              <p>
                Please note that GST will be charged in addition to the prices
                mentioned.
              </p>
            </div>
            <div className="container faq">
              <div className="row">
                <div className="col-md-12">
                  <h2>FAQ</h2>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer">
                      What is SEO?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          SEO is an essential skill for all businesses, but it
                          can be overwhelming when you first begin to explore
                          the world of optimizing your website. We're here to
                          simplify SEO for you by making it a simpler process
                          and providing innovative suggestions that will result
                          in organic growth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-2"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                      Does My Business Need SEO?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It is true that your company should invest in SEO. SEO
                          is an opportunity to boost the number of visitors to
                          your website without having to pay for every click.
                          However, when your site appears organically on search
                          engines, the traffic is free (at minimum in terms of
                          cost-per-click). So, naturally, getting your website
                          listed in the SERPs will require time and skills.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-3"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                      How Does SEO Work?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          SEO is the method of enhancing your site to be more
                          visible in search results. How does SEO work? Google
                          uses more than 200 ranking factors which allow the
                          algorithm of the search engine to rank websites based
                          on the authority and relevancy of their websites.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-4"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                      How Can I Find The Keywords That People Are Searching For
                      to Find Businesses Like Mine?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It isn't a good idea to start an SEO strategy without
                          knowing. You must understand what people are looking
                          to find on Google to find companies like yours and
                          then optimize your website in these terms. You can
                          achieve this with Google's Keyword Planner and the
                          SEMrush Keyword Magic Tool. You'll be presented with
                          an array of relevant keywords and their monthly search
                          volume.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-5"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                      Why Isn't My Website Ranking on Google?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Your website is not yet established and doesn't have
                          enough authority to rank well for competitive search
                          terms. You shouldn't start a new website and expect to
                          be ranked overnight for your targeted search terms.
                          You need to prove that you're worthy of being
                          organized.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-6"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                      Why Has My Organic Traffic Dropped?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Verify that your site has been accidentally tagged
                          with the Noindex tag added. This may happen when
                          developers transfer a website from an environment for
                          testing to live and fail to take these tags off. If
                          they remain on the site, they can cause your site to
                          be removed from the index.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-7"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                      What Is A Google Penalty?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          However, technically penalizations based on algorithms
                          (or modifications) aren't considered penalties.
                          They're the result of a website's rankings shifting
                          due to an algorithm (for example, those of the Panda
                          or Penguin algorithms) screening a website because of
                          the detection of manipulative activities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-8"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-8">
                      How Long Does It Take To Rank on Google?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          As is the answer to many SEOs: the answer depends,
                          Long away are days launching a site, improving your
                          title tags, and ranking within a couple of weeks.
                          Launching a site and expecting it to be ranked in the
                          top positions for competitive searches in a matter of
                          hours is impossible. The process takes time for
                          websites to be organically successful since Google
                          ranks websites with credibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-9"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-9">
                      What Are Google's Most Important Ranking Factors?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          In 2016, Google announced its top three ranking
                          factors: links, content and RankBrain.And optimizing
                          these factors involves creating high-quality web
                          content that other sites will want to link to and that
                          Google recognizes as the most effective result for any
                          search query.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-10"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-10">
                      Should I Do SEO, Run PPC Ads, or Both?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The most frequent question is whether SEO, PPC, or
                          both best serve a company. It's the enduring SEO vs
                          PPC debate. In an ideal world, a company must be able
                          to balance multiple marketing channels to avoid
                          relying on one source of revenue (and conversions).
                          Budgets are often a significant issue for small
                          businesses, and resources must be allocated
                          effectively. There isn't a limit to the amount of
                          money available.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-11"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-11">
                      Does Social Media Help To Increase My Website's Rank?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It's a popular belief that social media posting can
                          boost your website's ranking in search engines. While
                          social media won't directly improve your website's
                          rankings, it indirectly impacts and builds your brand.
                          Review writing is yet another tried and tested method
                          to build your brand. It leaves your customers feeling
                          that you're a company they can trust while improving
                          your search engine rankings.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-12"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-12">
                      Why is Content Marketing important?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Content is the foundation of all online activities
                          prior to making a purchase, starting with the first
                          search right up until the time they click to buy.
                          Without content, there are no websites such as reviews
                          on the internet, search results, demo videos or
                          anything else that can help buyers make the right
                          choices.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-18"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-18">
                      What are the benefits of Content Marketing?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The most important benefit is that it confidently
                          guides people toward purchasing from your business. In
                          a world where there are hundreds, or thousands of
                          other companies similar to yours, which people can buy
                          from, the content is the way to differentiate your
                          business and demonstrate why they should deal with
                          your company.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-29"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-29">
                      Why does my business need a Content Marketing Strategy?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          According to research conducted by CMI, most
                          businesses claim to do content marketing, but only 37%
                          claim to have a written strategy that guides their
                          actions. This being said, it shouldn't come as a shock
                          that just 9 percent of B2B marketers view their work
                          as sophisticated, based on the same study. Without the
                          existence of a strategy for content, it's like
                          shooting in the dark and isn't able to measure results
                          or spot opportunities to increase outcomes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-35"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-35">
                      What kind of result can I expect from Content Marketing?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It depends on what your goals and needs are. For
                          instance, an online news site looking to increase its
                          online reputation will be able to achieve different
                          goals from small-scale businesses looking to grow the
                          number of customers it serves.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
export default Seoandcontent;
<div className="main-wrap">
  <main className="content-wrap">
    <section className="section-works work-page">
      <div className="container"></div>
    </section>
  </main>
</div>;

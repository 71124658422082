import React, { Component } from "react";
import Worklist from "./layout/work/Worklist";
import { Helmet } from "react-helmet";

class Works extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Digital Marketing Agency & Digital Marketing Service in India
          </title>
          <meta
            name="description"
            content="Looking for digital marketing services ? With a team of highly efficient search marketing experts, we can help you achieve greater visibility, stand out from the rest, drive more traffic and convert that traffic into leads."
          />
          <meta
            name="keywords"
            content="video advertising companies,top digital marketing experts,paid advertising "
          />
        </Helmet>

        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <Worklist />
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Works;

import React, { Component } from "react";
import Iframe from "react-iframe";
import { ExternalLink } from "react-external-link";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import client1 from "../assets/img/seasonscafe/01.webp";
import image1 from "../assets/img/seasonscafe/02.webp";
import image2 from "../assets/img/seasonscafe/03.webp";
import image3 from "../assets/img/seasonscafe/04.webp";
import image4 from "../assets/img/seasonscafe/05.webp";
import image5 from "../assets/img/seasonscafe/06.webp";
import image6 from "../assets/img/seasonscafe/07.webp";
import image7 from "../assets/img/seasonscafe/08.webp";
import image8 from "../assets/img/seasonscafe/09.webp";
import image9 from "../assets/img/seasonscafe/10.webp";
import image10 from "../assets/img/seasonscafe/11.webp";
import image12 from "../assets/img/seasonscafe/12.webp";
import image13 from "../assets/img/seasonscafe/13.webp";
import image14 from "../assets/img/seasonscafe/14.webp";
import image15 from "../assets/img/seasonscafe/15.webp";
import image16 from "../assets/img/seasonscafe/16.webp";
import image17 from "../assets/img/seasonscafe/17.webp";
import image18 from "../assets/img/seasonscafe/18.webp";
import image19 from "../assets/img/seasonscafe/19.webp";
import image20 from "../assets/img/seasonscafe/20.webp";
import image21 from "../assets/img/seasonscafe/21.webp";
import image22 from "../assets/img/seasonscafe/22.webp";
import image23 from "../assets/img/seasonscafe/23.webp";
import kovaipalamudir from "../assets/img/kovaipazhamudirnilayam/01.webp";
import kpnsnack from "../assets/img/kpnsnacks/01.webp";
import eauditor from "../assets/img/eauditoroffice/01.webp";
import ourinfinesnack from "../assets/img/ourinfine/ourInfine.webp";
import edurekalearning from "../assets/img/edureka/Edureka.webp";
class Seasonscafe extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
    return (
      <div>
        <Helmet>
          <title>
            Corporate Films, AD Films, TV ads, Video Making, Advertising -
            Bluekodedigital
          </title>
          <meta
            name="description"
            content="A leading corporate film production agency in cbe,We guide our clients in the creation of captivating content that will help them gain attention, maximize productivity and build their brand."
          />
          <meta
            name="keywords"
            content="brand advertising company,digital marketing companies in coimbatore"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <div className="row align-items-center main-image">
                  <div className="col-lg-6">
                    <div className="project-grid">
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={client1}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="col-lg-6 datas-pages">
                    <h1 className="project-description__title work-in-font-h1">
                      Seasons Cafe
                    </h1>
                    <p className="project-description__text text-large work-in-font">
                      Seasons Cafe is a well-known restaurant that serves
                      delicious food. We helped Seasons Cafe scale up their
                      social media presence and implemented a strategic plan to
                      bring more customers through the door. By creating content
                      that resonates with Seasons Cafe's target audience, we
                      have helped grow the restaurant's online reputation.
                    </p>
                    <div className="project-info-item">
                      <span className="project-info-item__label">Services</span>
                      <span className="project-info-item__title">
                        {" "}
                        Advertising, Digital Marketing, UI/UX, Creative
                        Production.
                      </span>
                    </div>
                    <ExternalLink
                      href="http://seasonscafe.in"
                      className="project-description__link"
                    >
                      seasonscafe.in
                      <i className="dorya-icon-arrow-diagonal-up"></i>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Creative Ads
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image21}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image12}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image13}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image14}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image15}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image16}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image17}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image18}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image19}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image20}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>

                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image22}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image23}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image1}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image2}
                                className="project__img hover-shrink--zoom"
                                alt="Advertising Agency in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image3}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image4}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Photography
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image5}
                                className="project__img hover-shrink--zoom"
                                alt="Best Marketing Agencies in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image6}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Agency"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image7}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image8}
                                className="project__img hover-shrink--zoom"
                                alt="Corporate Films"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Packaging Design
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image9}
                                className="project__img hover-shrink--zoom"
                                alt="Online Marketing company "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image10}
                                className="project__img hover-shrink--zoom"
                                alt="UI & UX Design Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Motion Graphics
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/V7-f9yecN98"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/TGsWVIXi0yo"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>

                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/dQpwPGE2omw"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/b7LdL0L65Xc"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/QnGUOE5lheM"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>

                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/njf-VJOXSJ0"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/Zza97cpj6e4"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/ZhSeQNOMBUE"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/cJSkW7lcuog"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/uy0vv7Pm17A"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/xiP3knIO6lo"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/W0NORFwcbxM"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/zb2hccr7vys"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/eoJt8CfCYuk"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/ucjqCfBFxlY"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/JbLSaY0p1tI"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Next Projects
                    </h1>
                  </div>
                </div>

                <div className="project-grid project-align">
                  <Carousel responsive={responsive} infinite>
                    {/* autoPlay infinite */}
                    <div>
                      <article className="grid-item project hover-shrink featured branding product kovai">
                        <div className="project__container">
                          <Link
                            to="/kovaipazhamudirnilayam"
                            className="project__url"
                          >
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={kovaipalamudir}
                                className="project__img hover-shrink--zoom"
                                alt="Advertising Agency in Coimbatore"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/kovaipazhamudirnilayam">
                                  Kovai Pazhamudir Nilayam
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/kpnsnacks" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={kpnsnack}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Services"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/kpnsnacks">KPN Snacks</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/eauditoroffice" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={eauditor}
                                className="project__img hover-shrink--zoom"
                                alt="Best Marketing Agencies in Coimbatore"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/eauditoroffice">
                                  E-Auditor Office
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/ourinfine" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={ourinfinesnack}
                                className="project__img hover-shrink--zoom"
                                alt="Our Infine "
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/ourinfine">Our Infine</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/edureka" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={edurekalearning}
                                className="project__img hover-shrink--zoom"
                                alt="Edureka "
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/edureka">Edureka</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Seasonscafe;

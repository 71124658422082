import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import arvr from "../../../assets/img/blogs/ar-vr.webp";

class Arvr extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    
    return (
      <div>
        <Helmet>
          <title>
            Top Digital Marketing Companies in coimbatore - Bluekodedigital
          </title>
          <meta
            name="description"
            content="Best Digital Marketing in Coimbatore- helps you reach a bigger audience and engage with them effectively."
          />
          <meta
            name="keywords"
            content="paid advertising services,digital marketing professionals,big advertising companies"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <div className="row align-items-center main-image">
                  <div className="col-lg-6">
                    <div className="project-grid">
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={arvr}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="col-lg-6 datas-pages">
                    <h1 className="project-description__title work-in-font-h1">
                      The Use of Augmented Reality (AR) and Virtual Reality (VR)
                      in the Creative Industry
                    </h1>
                    <p className="project-description__text text-large work-in-font">
                      The creative industry is constantly evolving and adapting
                      to new technologies and advancements. Augmented Reality
                      (AR) and Virtual Reality (VR) are two of the latest and
                      most innovative technologies that are making waves in the
                      creative industry. These technologies are being used in a
                      variety of ways, from product visualization to immersive
                      experiences, and they are having a major impact on the way
                      that creative professionals approach their work.
                    </p>
                  </div>
                </div>
                <div className="row align-items-center main-image">
                  <div className="col-lg-12 mt-30">
                    <p className="project-description__text text-large work-in-font">
                      AR technology allows for digital content to be overlaid
                      onto the physical world, creating a new level of
                      interaction and engagement. In the creative industry, this
                      technology is being used for product visualization,
                      allowing customers to see products in real-world settings
                      before making a purchase. AR is also being used to create
                      interactive experiences that allow customers to explore
                      and interact with products in new and exciting ways.
                    </p>
                    <p className="project-description__text text-large work-in-font">
                      VR technology, on the other hand, creates a fully
                      immersive digital environment. In the creative industry,
                      this technology is being used for product visualization,
                      training, and marketing. VR allows for the creation of
                      virtual showrooms and events, making it easier for
                      customers to experience products and services without
                      physically being there. VR is also being used to create
                      training simulations, allowing employees to practice and
                      perfect their skills in a safe and controlled environment.
                    </p>
                    <p className="project-description__text text-large work-in-font">
                      Another important use of AR and VR in the creative
                      industry is in the field of marketing and advertising.
                      These technologies are being used to create immersive and
                      interactive experiences that engage customers and leave a
                      lasting impression. From virtual tours to interactive
                      product demonstrations, AR and VR are allowing marketers
                      to tell their story in new and exciting ways.{" "}
                    </p>

                    <p className="project-description__text text-large work-in-font">
                      In conclusion, the use of AR and VR in the creative
                      industry is growing at an unprecedented rate, and it's
                      changing the way that creative professionals approach
                      their work. These technologies are allowing for new levels
                      of interaction, immersion, and engagement, and they are
                      providing a wide range of opportunities for product
                      visualization, training, marketing, and advertising. As
                      the creative industry continues to evolve and embrace new
                      technologies, we can expect to see even more innovative
                      and exciting uses of AR and VR in the years to come.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Arvr;

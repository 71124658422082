import React, { Component } from "react";
import Iframe from "react-iframe";
import { ExternalLink } from "react-external-link";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import client1 from "../assets/img/edureka/Edureka.webp";
import image1 from "../assets/img/edureka/1.webp";
import image2 from "../assets/img/edureka/2.webp";
import image3 from "../assets/img/edureka/3.webp";
import image4 from "../assets/img/edureka/4.webp";
import image5 from "../assets/img/edureka/5.webp";
import image6 from "../assets/img/edureka/6 .webp";

import { Link } from "react-router-dom";
import kovaipalamudir from "../assets/img/kovaipazhamudirnilayam/01.webp";
import season from "../assets/img/seasonscafe/01.webp";
import kpnsnack from "../assets/img/kpnsnacks/01.webp";
import eauditor from "../assets/img/eauditoroffice/01.webp";
import ourinfinesnack from "../assets/img/ourinfine/ourInfine.webp";
class Edureka extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
    return (
      <div>
        <Helmet>
          <title>
            Corporate Films, AD Films, TV ads, Video Making, Advertising -
            Bluekodedigital
          </title>
          <meta
            name="description"
            content="A leading corporate film production agency in cbe,We guide our clients in the creation of captivating content that will help them gain attention, maximize productivity and build their brand."
          />
          <meta
            name="keywords"
            content="brand advertising company,digital marketing companies in coimbatore"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <div className="row align-items-center main-image">
                  <div className="col-lg-6">
                    <div className="project-grid">
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={client1}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="col-lg-6 datas-pages">
                    <h1 className="project-description__title work-in-font-h1">
                      Edureka
                    </h1>
                    <p className="project-description__text text-large work-in-font">
                      The Edureka training institute is a very well-known
                      reputed training institute. Its RS Puram & Peelamedu
                      branch located in Coimbatore needed to build its social
                      media presence. Hence, we worked on creating unified
                      social media posters & ads with engaging content & perfect
                      designs that have successfully increased walk-ins &
                      enquires.
                    </p>
                    <div className="project-info-item">
                      <span className="project-info-item__label">Services</span>
                      <span className="project-info-item__title">
                        {" "}
                        Advertising, Digital Marketing.
                      </span>
                    </div>
                    <ExternalLink
                      href="https://www.edureka.co/"
                      className="project-description__link"
                    >
                      edureka.co<i className="dorya-icon-arrow-diagonal-up"></i>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Creative Ads
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image1}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image2}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image3}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image4}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image5}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image6}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Motion Graphics
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/6I3LD62Cvw8"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/Gwp6nuPCt8s"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/v56PagHnH9g"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Next Projects
                    </h1>
                  </div>
                </div>

                <div className="project-grid project-align">
                  <Carousel responsive={responsive} infinite>
                    {/* autoPlay infinite */}
                    <div>
                      <article className="grid-item project hover-shrink featured branding product kovai">
                        <div className="project__container">
                          <Link
                            to="/kovaipazhamudirnilayam"
                            className="project__url"
                          >
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={kovaipalamudir}
                                className="project__img hover-shrink--zoom"
                                alt="Advertising Agency in Coimbatore"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/kovaipazhamudirnilayam">
                                  Kovai Pazhamudir Nilayam
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/seasonscafe" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={season}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/seasonscafe">Seasons Cafe</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/kpnsnacks" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={kpnsnack}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Services"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/kpnsnacks">KPN Snacks</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/eauditoroffice" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={eauditor}
                                className="project__img hover-shrink--zoom"
                                alt="Best Marketing Agencies in Coimbatore"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/eauditoroffice">
                                  E-Auditor Office
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/ourinfine" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={ourinfinesnack}
                                className="project__img hover-shrink--zoom"
                                alt="Our Infine "
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/ourinfine">Our Infine</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Edureka;

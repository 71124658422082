import React, { Component } from "react";
class Services extends Component {
  render() {
    return (
      <div>
        <section className="home-section-3 pb-20">
          <div className="container">
          <h1 className="project-description__title work-in-font-h1">
              Our Services
            </h1>
            <div className="row justify-content-center">
              <div className="col-lg-5 service-tab-menu">
                <ul className="nav nav-pills">
                  <li>
                    <span
                      data-toggle="pill"
                      href="#brandstrategy"
                      className="active"
                    >
                      Brand Strategy
                    </span>
                  </li>
                  <li>
                    <span data-toggle="pill" href="#advertising">
                      Advertising
                    </span>
                  </li>
                  <li>
                    <span data-toggle="pill" href="#digitalmarketing">
                      Digital Marketing
                    </span>
                  </li>
                  <li>
                    <span data-toggle="pill" href="#creativeproduction">
                      {" "}
                      Production
                    </span>
                  </li>
                  <li>
                    <span data-toggle="pill" href="#uiux">
                      UI / UX
                    </span>
                  </li>
                  <li>
                    <span data-toggle="pill" href="#tvc">
                      TVC
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-7 service-tab-data">
                <div className="tab-content">
                  <div id="brandstrategy" className="tab-pane active">
                    <p>
                      Brand Creation • Rebranding • Visual language • Brand
                      Identity • Graphic Design • Art Direction{" "}
                    </p>
                  </div>
                  <div id="advertising" className="tab-pane">
                    <p>
                      Social Media Strategy • Print Campaigns • Environmental
                      Design • Radio • OOH{" "}
                    </p>
                  </div>
                  <div id="digitalmarketing" className="tab-pane">
                    <p>
                      Content Strategy • SEO &#38; SMM, • Email Marketing • Paid
                      Advertising
                    </p>
                  </div>
                  <div id="creativeproduction" className="tab-pane">
                    <p>
                      Photography • Post Production • Animation • Motion
                      Graphics • Copywriting
                    </p>
                  </div>
                  <div id="uiux" className="tab-pane">
                    <p>
                      Website &#38; Apps • Back End • CRM Integrations •
                      Ecommerce
                    </p>
                  </div>
                  <div id="tvc" className="tab-pane">
                    <p>
                      Storyboard • Script Writing • Ad Films • Multimedia
                      Production
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Services;

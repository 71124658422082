import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import client1 from "../../../assets/img/kovaipazhamudirnilayam/01.webp";
import client2 from "../../../assets/img/seasonscafe/01.webp";
import client3 from "../../../assets/img/kpnsnacks/01.webp";
import client4 from "../../../assets/img/eauditoroffice/01.webp";
import client5 from "../../../assets/img/ourinfine/ourInfine.webp";
import client6 from "../../../assets/img/edureka/Edureka.webp";
import { Link } from "react-router-dom/cjs/react-router-dom";
gsap.registerPlugin(ScrollTrigger);

export default function Portfolios() {
  useEffect(() => {
    const width = window.innerWidth;
    if (width > 992) {
      const sections = gsap.utils.toArray(".portfolio__item");

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".portfolio",
          pin: true,
          scrub: 1,
          start: "top 50",
          end: () => `+=${document.querySelector(".portfolio").offsetWidth}`,
        },
      });
    }
  }, []);
  return (
    <div>
      <section className="home-section-2 pb-10">
        <div className="container">
          <h1 className="project-description__title work-in-font-h1">
            Our Works
          </h1>
          <div className="portfolio">
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/kovaipazhamudirnilayam">
                  <figure>
                    <img src={client1} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/kovaipazhamudirnilayam">
                    Kovai Pazhamudir Nilayam
                  </Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/kovaipazhamudirnilayam" >Know more <span  className="fa fa-arrow-right"></span></Link>
                </div>
              </div>
            </div>
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/seasonscafe">
                  <figure>
                    <img src={client2} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/seasonscafe">Seasons Cafe</Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/seasonscafe">Know more <span  className="fa fa-arrow-right"></span> </Link>
                </div>
              </div>
            </div>
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/kpnsnacks">
                  <figure>
                    <img src={client3} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/kpnsnacks">KPN Snacks</Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/kpnsnacks">Know more <span  className="fa fa-arrow-right"></span> </Link>
                </div>
              </div>
            </div>
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/eauditoroffice">
                  <figure>
                    <img src={client4} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/eauditoroffice">E-Auditor Office</Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/eauditoroffice">Know more <span  className="fa fa-arrow-right"></span> </Link>
                </div>
              </div>
            </div>
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/ourinfine">
                  <figure>
                    <img src={client5} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/ourinfine">Our Infine</Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/ourinfine">Know more <span  className="fa fa-arrow-right"></span> </Link>
                </div>
              </div>
            </div>
            <div className="portfolio__item">
              <div className="portfolio__item--container">
                <Link to="/edureka">
                  <figure>
                    <img src={client6} alt="Kovai Pazhamudir Nilayam" />
                  </figure>
                </Link>
              </div>
              <div className="portfolio__desc--wrap">
                <h3>
                  <Link to="/edureka">Edureka</Link>
                </h3>
                <div className="portfolio__desc--btn">
                  <Link to="/edureka">Know more <span  className="fa fa-arrow-right"></span> </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 more-works">
                <div className="grid-item project">
                  <Link
                    to="/works"
                    className="all-projects title-link underline animate-letters"
                  >
                    More Works
                  </Link>
                </div>
              </div>
        </div>
      </section>
    </div>
  );
}

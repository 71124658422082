import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";
function Branding() {
  const [annually] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">Branding Packages in India</h2>
                        <p>
                          Let’s talk about your branding. What’s the vision for
                          your business? We want to help you get there.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                30000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>
                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div
                          className="price-list pzero table-section"
                          style={{ marginTop: "1em" }}
                        >
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Brand Strategy & Positioning
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Your Target Audience
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Mission
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Voice
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Personality
                              </li>
                            </ul>
                            <br />
                          </div>
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-c1"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-c1"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Visual Identity
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Logo Design (2 Designs)
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monogram (or) Lettermark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wordmark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Combination Mark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Color Theory
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Messaging
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Tagline
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <li className="bold t-head color-blue">
                                      Brand Assets
                                    </li>
                                    <li className="bold t-head color-blue">
                                      {" "}
                                      Revisions{" "}
                                      <span className="blue">
                                        {" "}
                                        (Upto 2 Revisions)
                                      </span>
                                    </li>
                                    <li className="bold t-head color-blue">
                                      Deliverables{" "}
                                      <span className="blue">
                                        {" "}
                                        (5 - 7 Business Days)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                40000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div
                          className="price-list pzero table-section"
                          style={{ marginTop: "1em" }}
                        >
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Brand Strategy & Positioning
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Your Target Audience
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Mission
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Voice
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Personality
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-c2"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-c2"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Visual Identity
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Logo Design (3 Designs)
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monogram (or) Lettermark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wordmark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Combination Mark
                                    </li>

                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Color Theory
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      The Emblem
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Typography
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Messaging
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Tagline
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Corporate Identites
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Business Cards
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Letterhead
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Envelope
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      ID Card
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      T-Shirt
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <li className="bold">Brand Guidelines</li>
                                    <li className="bold">Brand Assets</li>
                                    <li className="bold">
                                      {" "}
                                      Revisions <span> (Upto 3 Revisions)</span>
                                    </li>
                                    <li className="bold">
                                      Deliverables{" "}
                                      <span> (7 - 10 Business Days)</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                50000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div
                          className="price-list pzero table-section"
                          style={{ marginTop: "1em" }}
                        >
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Brand Strategy & Positioning
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Your Target Audience
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Mission
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Voice
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Brand Personality
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-c3"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-c3"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Visual Identity
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Logo Design (5 Designs)
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monogram (or) Lettermark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wordmark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Combination Mark
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Color Theory
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      The Emblem
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Typography
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Pictorial (or) Abstract
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Mascots
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Messaging
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Tagline
                                    </li>
                                  </ul>
                                </div>

                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Corporate Identites
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Business Cards
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Letterhead
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Envelope
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      ID Card
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      T-Shirt
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <li className="bold t-head color-blue">
                                      Brand Guidelines
                                    </li>
                                    <li className="bold t-head color-blue">
                                      Logo Animation
                                    </li>
                                    <li className="bold t-head color-blue">
                                      Brand Assets
                                    </li>
                                    <li className="bold t-head color-blue">
                                      {" "}
                                      Revisions{" "}
                                      <span className="blue">
                                        {" "}
                                        (Upto 5 Revisions)
                                      </span>
                                    </li>
                                    <li className="bold t-head color-blue">
                                      Deliverables{" "}
                                      <span className="blue">
                                        {" "}
                                        (10 - 14 Business Days)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container text-center">
              <p>
                Please note that GST will be charged in addition to the prices
                mentioned.
              </p>
            </div>
            <div className="container faq">
              <div className="row">
                <div className="col-md-12">
                  <h2>FAQ</h2>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer">
                      What is a brand, and what is branding?{" "}
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Your brand represents how people see you, not only
                          from a marketing perspective but in a broad sense.
                          It's how people feel and think about when they look at
                          your business, and can be influenced by each
                          interaction that they encounter with your company.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-2"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                      Why is branding important?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The quality of branding has many amazing benefits, and
                          we'd like to spotlight the most important. Firstly it
                          enhances your image and alters how people see your
                          brand for the better.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-3"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                      What are the key Factors of a brand identity?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          In general, four important elements make up a brand's
                          identity: a logo, colour palette typeface(s) as well
                          as the voice of the brand. Each of these must be
                          carefully coordinated to create integrated branding.
                          It is often believed that logos are the company's
                          identity, but it's just one aspect of it.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-4"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                      How do we make a brand identity? How do we create a brand?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The primary part of creating the brand's identity is
                          to define the visual elements that comprise your
                          branding. This includes an identity, colour palette
                          typeface(s), and the brand's voice. These components
                          should be designed and coordinated to create coherent
                          branding.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-5"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                      Is a logo a brand?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          A logo is only a part of a brand's identity, and a
                          brand's identity is an integral part of the brand. A
                          Brand is what one thinks and feels about as one
                          engages with a company. It is affected not only
                          through marketing but each interaction that people
                          have with the business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-6"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                      What is the difference between marketing and branding?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Branding is the basis of marketing. A clear brand
                          identity is a way to determine how each piece of
                          marketing has to look and sound. Essentially branding
                          defines the narrative a business wants to tell and the
                          guidelines for communicating with its customers. Then
                          marketing is employed to convey the message
                          consistently. Marketing is the channel that lets a
                          brand's message out into the world.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-7"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                      How do payments work?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          The majority of projects are split into two
                          instalments that are a 50 per cent deposit and a 50
                          percent final payment including 18% of GST. For larger
                          projects, we can establish dates for the price. Fees
                          can be paid via Bank transfer or cheque.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-8"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-8">
                      How long does it take to complete a branding project?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Depending on the number of deliverables required, It
                          can take me approximately a week for the logo design
                          and up to one month for a typical branding package,
                          which includes other collateral pieces.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-9"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-9">
                      How will we communicate?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          We'll start with Zoom (or) Google Meet video calls to
                          discuss your ideas and then present the initial round
                          of ideas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-10"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-10">
                      Do you provide editable source files?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Yep. Certain branding agencies charge an extra fee for
                          these. However, we do not.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-11"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-11">
                      Can you manage the printing of my project?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Yes. Certain clients already have reliable printers
                          they trust and only require print-ready documents they
                          can send. If needed, we will manage the entire
                          printing process on your behalf by recommending
                          suppliers, getting estimates, and guiding your project
                          through to the end.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-12"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-12">
                      How can I be sure I'll like what you design?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          A branding project requires lots of gathering
                          information, a deep-dive discussion with you, and a
                          review of examples to get your opinions before any
                          project is developed. We'll talk about your goals for
                          the business and history, your target audience, your
                          competitors and much more. These are the parameters
                          that will guide the design.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Branding;

import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Banner from "./layout/home/Banner";
import Aboutus from "./layout/home/Aboutus";
// import Portfolio from "./layout/home/Portfolio";
import Services from "./layout/home/Services";
import Team from "./layout/home/Team";
import Partner from "./layout/home/Partner";
import Blog from "./layout/home/Blog";
import "../assets/css/custom.css";
import "../assets/css/modal.css";
import "../assets/css/pricingcustom.css";
import "../assets/css/homecustom.css";

// import ClientsCarousel from "./layout/home/Clients";
import Portfolios from "./layout/home/Portfolios";

class Home extends Component {
  render() {
    return (
      <div>
        <div className="main-wrap">
          <main className="content-wrap">
            <Helmet>
              <title>
                Advertising agency | Best Marketing Agencies in Coimbatore |
                Bluekodedigital
              </title>
              <meta
                name="description"
                content="Best Marketing Agencies in Coimbatore ? We provide complete marketing services with a firm focus on strategy and creativity."
              />
              <meta
                name="keywords"
                content="best ad agencies,digital marketing expert near me,best advertising company in coimbatore"
              />
            </Helmet>
            <Banner />
            <Aboutus />
            {/* <Portfolio /> */}
            <Portfolios/>
            <Services />
            {/* <ClientsCarousel /> */}
            <Blog />
            <Team />
            <Partner />
          </main>
        </div>
      </div>
    );
  }
}

export default Home;

import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";
function Ecommerce() {
  const [annually] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          E-Commerce Website Design & Development Packages in
                          India
                        </h2>
                        <p>
                          E-commerce is an integral part of today’s business
                          world. Get an online store you can be proud of by
                          hiring us!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                100000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Upto 10 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2 Website Theme
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Cloud Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      5 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      100GB SSD Storage
                                    </li>

                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      1GB RAM
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wallet and Referral Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Abandoned Checkout
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Control Panel
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google Analytics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Pixel Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Search Console Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Payment Gateway Integration
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                125000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                15 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                3 Website Theme
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Cloud Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      7 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      150GB SSD Storage
                                    </li>

                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2GB RAM
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wallet and Referral Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Abandoned Checkout
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Control Panel
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google Analytics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Pixel Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Whatsapp Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Standard Ecommerce Policies
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Payment Gateway Integration
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                150000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                20 Pages
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                5 Website Theme
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Mobile Responsive
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                SEO Friendly
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Domain - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Cloud Hosting - 1st year free
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Unlimited Free SSL
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Cloudflare Protected Nameservers
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      10 Business Email's
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      200GB SSD Storage
                                    </li>

                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      3GB RAM
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Wallet and Referral Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Abandoned Checkout
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Control Panel
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Google Analytics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Pixel Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Whatsapp Integration
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Standard Ecommerce Policies
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Payment Gateway Integration
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Yearly
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="Print-media">
                <div className="container">
                  <div className="row  pzero margin-b">
                    <div className="col-lg-3 col-md-12"></div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table-below " id="pricing">
                        <div className="price-header-below box-print">
                          <h3 className="price-title">Android App</h3>
                          <p>from</p>
                          <h4 className="dol-h4">
                            <span className="dollar">
                              <span className="">&#8377;</span>{" "}
                            </span>
                            80000
                          </h4>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table-below " id="pricing">
                        <div className="price-header-below box-print">
                          <h3 className="price-title">iOS App</h3>
                          <p>from</p>
                          <h4 className="dol-h4">
                            <span className="dollar">
                              <span className="">&#8377;</span>{" "}
                            </span>
                            100000
                          </h4>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12"></div>
                  </div>
                </div>
                <div className="container">
                  <div className="row  pzero margin-b">
                    <div className="col-lg-3 col-md-12"></div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table-below " id="pricing">
                        <div className="price-header-below box-print">
                          <h3 className="price-title">Cross-platform App</h3>
                          <p>from</p>
                          <h4 className="dol-h4">
                            <span className="dollar">
                              <span className="">&#8377;</span>{" "}
                            </span>
                            90000
                          </h4>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table-below " id="pricing">
                        <div className="price-header-below box-print">
                          <h3 className="price-title">Web App</h3>
                          <p>from</p>
                          <h4 className="dol-h4">
                            <span className="dollar">
                              <span className="">&#8377;</span>{" "}
                            </span>
                            100000
                          </h4>
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12"></div>
                  </div>
                </div>
                <div className="container text-center mt-60">
                  <p>
                    Please note that GST will be charged in addition to the
                    prices mentioned.
                  </p>
                </div>
                <div className="container faq">
                  <div className="row">
                    <div className="col-md-12">
                      <h2>FAQ</h2>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer">
                          How will a well-designed website help my Business?{" "}
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              A well-designed website can enhance users'
                              experience and is essential for gaining more
                              conversions. If you design your website that is
                              well-organized, your visitors and customers will
                              feel comfortable with the website, and it will
                              draw increasing numbers of potential customers.
                              The initial step towards your success is creating
                              a site's visitors and customers feel attractive
                              and appealing only by having an attractive and
                              well-designed web page.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-2"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                          What is the role of an ecommerce website developer?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              Web developers for ecommerce are essential to
                              ensuring potential customers can navigate easily
                              through your online store, locate the items
                              they're looking for, and pay easily and safely.
                              They can use a variety of abilities, including
                              backend programming, to frontend ecommerce website
                              design. Their duties can vary based on the kind of
                              store you own.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-3"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                          How do I determine what features my ecommerce website
                          needs?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              The essential capabilities and features of your
                              online store will differ based on the nature of
                              your business and its unique requirements. Based
                              on your goals and goals, outline the features your
                              eCommerce website requires. These must be aligned
                              with your customer's lifecycle.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-4"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                          What kind of support can I expect after the store
                          launch?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              If you're not working in the world of e-commerce
                              If so, you'll require plenty of after-sales
                              assistance. This can assist you in solving bugs
                              and design issues and optimizing newly developed
                              processes. Top ecommerce development firms provide
                              at least four months of support to medium-sized
                              online stores and up to 12 months of assistance
                              with any technical issues arising from their
                              developments.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-5"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                          What do you require from my side?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              Most online store designers initially request
                              goals, budgets, and feature requirements to
                              develop the best proposal. Then comes the catalog
                              of products, including product photos, product
                              descriptions, product information, and many other
                              things. Making a strong web-based store requires a
                              team process, and if an online store developer
                              states all he needs is a deposit to begin,
                              consider it a red flag.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-6"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                          Will you build a custom online store or use a ready
                          solution?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              A design and development team can deliver custom
                              design, use a pre-designed template, or combine
                              both when designing your ecommerce store. Online
                              stores built from scratch using modern development
                              technologies are powerful, scalable, and slightly
                              expensive. Stores built on SaaS solutions can be
                              launched super quickly but falter as your store
                              grows.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq-drawer">
                        <input
                          className="faq-drawer__trigger"
                          id="faq-drawer-7"
                          type="checkbox"
                        />
                        <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                          Will my ecommerce store be easily scalable with my
                          business?
                        </label>
                        <div className="faq-drawer__content-wrapper">
                          <div className="faq-drawer__content">
                            <p>
                              Technology is everything when it comes to building
                              an online store. It happens very often that
                              entrepreneurs start their online stores with a
                              solution that is not scalable with their business
                              and later on have to rebuild everything. Hence,
                              this is something that requires all the attention
                              you can give. Top ecommerce development firms
                              understand this and their products are highly
                              scalable horizontally as well as vertically.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Ecommerce;

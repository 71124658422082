import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";
function Production() {
  const [annually] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          Video Marketing Packages in India
                        </h2>

                        <p>
                          Video marketing is a great way to share your brand and
                          message with a large audience.
                        </p>

                        <h3 className="title mt-30">SOCIAL MEDIA VIDEOS</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                5000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Discovery, Engagement
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                15 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <br />
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Discovery, Engagement
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      15 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                8000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Discovery, Engagement
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                30 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <br />
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Discovery, Engagement
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      30 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                12000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Discovery, Engagement
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                60 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Artificial Voice-Over
                              </li>
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Discovery, Engagement
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      60 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Artificial Voice-Over
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h3 className="title mt-30">
                          CONTENT MARKETING VIDEOS
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                10000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Educate, Entertain
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                30 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Motion Graphics
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Artificial Voice-Over
                              </li>
                              <br />
                              <br />
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-31"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-31"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Educate, Entertain
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      30 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Motion Graphics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Artificial Voice-Over
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                15000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Educate, Entertain
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                60 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Motion Graphics
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Artificial Voice-Over
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Stock Footage
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Explainer
                              </li>
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-32"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-32"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Educate, Entertain
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      60 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Motion Graphics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Artificial Voice-Over
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Stock Footage
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Explainer
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                20000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Educate, Entertain
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Facebook, Instagram, Youtube & LinkedIn
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                90 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Motion Graphics
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Artificial Voice-Over
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Stock Footage
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Explainer
                              </li>
                              <br />
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-33"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-33"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Educate, Entertain
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Facebook, Instagram, Youtube & LinkedIn
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      90 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Motion Graphics
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Artificial Voice-Over
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Stock Footage
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Explainer
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center mb-30">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h3 className="title mt-30">CONVERSION VIDEOS</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                5000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">&8377;</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Tell a Story, build trust
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Home (or) About us page
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                5 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-34"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-34"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Tell a Story, build trust
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Home (or) About us page
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      5 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar">
                                  <span>&#8377;</span>
                                </span>{" "}
                                7000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className="white dollarcut">
                                <span className="dollar ">$</span> 350.99
                              </h4>
                              <h3 className="flex items-center justify-center text-white text-5xl rate white">
                                <span className="dollar white">$</span> 249.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Tell a Story, build trust
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Home (or) About us page
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                10 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-35"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-35"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Tell a Story, build trust
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Home (or) About us page
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      10 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  <span>&#x20B9;</span>
                                </span>{" "}
                                10000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollar ">$</span> 499.99
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">$</span> 399.99
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section onlyweb">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">
                                Tell a Story, build trust
                              </h6>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Home (or) About us page
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                15 secs
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                2d Animation
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Sound Effects/bgm
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mobonly">
                          <div className="faq-drawer for-mob">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-36"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-36"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      Tell a Story, build trust
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Home (or) About us page
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      15 secs
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      2d Animation
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Sound Effects/bgm
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section className="Print-media">
              <div className="container">
                <div className="row pzero margin-b">
                  <div className="col-lg-3 col-md-12"></div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Photography</h3>
                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          50000
                        </h4>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => setModalShow(true)}
                        className="btn btn-theme my-4"
                        data-text="Get Started"
                      >
                        <span>G</span>
                        <span>e</span>
                        <span>t</span>
                        <span>&nbsp;</span>
                        <span>S</span>
                        <span>t</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>e</span>
                        <span>d</span>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="price-table-below " id="pricing">
                      <div className="price-header-below box-print">
                        <h3 className="price-title">Video Production</h3>
                        <p>from</p>
                        <h4 className="dol-h4">
                          <span className="dollar">
                            <span className="">&#8377;</span>{" "}
                          </span>
                          100000
                        </h4>
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => setModalShow(true)}
                        className="btn btn-theme my-4"
                        data-text="Get Started"
                      >
                        <span>G</span>
                        <span>e</span>
                        <span>t</span>
                        <span>&nbsp;</span>
                        <span>S</span>
                        <span>t</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>e</span>
                        <span>d</span>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12"></div>
                </div>
              </div>
              <div className="container text-center mt-60">
                <p>
                  Please note that GST will be charged in addition to the prices
                  mentioned.
                </p>
              </div>
              <div className="container faq">
                <div className="row">
                  <div className="col-md-12">
                    <h2>FAQ</h2>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer">
                        What Is Video Marketing?{" "}
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            Let's first address an issue that is one of the
                            commonly asked concerns. Video marketing is the kind
                            of marketing that uses videos for marketing your
                            service or product. It is also used to boost the
                            brand's awareness. The video content you choose
                            could include video ads and other types of video.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-2"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                        Is Video Marketing Worth the Effort?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            Video marketing can be extremely useful. The numbers
                            are clear. A staggering 86% of marketing experts use
                            video marketing as an element of their marketing
                            campaigns. This is not surprising, considering that
                            the use of video is always increasing.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-3"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                        What Is the Best Length for a Marketing Video?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            This is a tricky question! There's no ideal length
                            for the video. It's dependent on the kind of video
                            you're posting and the location you're posting it.
                            Video ads are typically less than a couple of
                            seconds. A customer's tutorial can be quite lengthy
                            - up to ten or 15 minutes, especially if you're
                            giving a tutorial that is quite complex.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-4"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                        What Makes a Great Video?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            There are a variety of solutions to the question of
                            how to create a fantastic film for your business. A
                            good marketing video fulfills its purpose, is
                            thought out and is in line with the brand you're
                            trying to establish. It should be able to tell the
                            tale of your brand and will be in line with your
                            message.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-5"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                        Why Should You Focus on Video Marketing?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            Video marketing today has grown to a $ 135 billion
                            business. While this may appear daunting, do not
                            worry! One of the major advantages of using video
                            marketing is the flexibility and a low barrier to
                            entry. You can begin with a budget that is as low or
                            high as you'd like. Employ a full-service agency
                            with all the bells and whistles needed to turn your
                            ideas into the video you've always wanted, or grab
                            your phone to record and create a video
                            advertisement in an Instagram story for less than 10
                            cents per click.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-6"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                        Is There a Proven Methodology for Video Marketing
                        Success?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            There's no definitive way to make video marketing
                            successful; however, those who have had the benefits
                            of videos agree that there is an important point of
                            departure: come to a plan and adhere to it. This
                            plan should be extensive and include more than the
                            simple "let's make one video a month!" Start with
                            identifying your objectives.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="faq-drawer">
                      <input
                        className="faq-drawer__trigger"
                        id="faq-drawer-7"
                        type="checkbox"
                      />
                      <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                        What are Some Examples of Successful Video Marketing?
                      </label>
                      <div className="faq-drawer__content-wrapper">
                        <div className="faq-drawer__content">
                          <p>
                            Now that you've learned the amazing advantages of
                            video marketing, let's look at the basics. Video is
                            most effective when it stirs emotions or educates
                            the viewer to do something. But, of course, certain
                            videos can do this better than others.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </main>
      </div>
    </>
  );
}

export default Production;

import React, { Component } from "react";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import client1 from "../assets/img/kovaipazhamudirnilayam/01.webp";
import image1 from "../assets/img/kovaipazhamudirnilayam/02.webp";
import image2 from "../assets/img/kovaipazhamudirnilayam/03.webp";
import image3 from "../assets/img/kovaipazhamudirnilayam/04.webp";
import image4 from "../assets/img/kovaipazhamudirnilayam/05.webp";
import image5 from "../assets/img/kovaipazhamudirnilayam/06.webp";
import image6 from "../assets/img/kovaipazhamudirnilayam/07.webp";
import image7 from "../assets/img/kovaipazhamudirnilayam/08.webp";
import image8 from "../assets/img/kovaipazhamudirnilayam/09.webp";
import image9 from "../assets/img/kovaipazhamudirnilayam/10.webp";
import image10 from "../assets/img/kovaipazhamudirnilayam/11.webp";
import image11 from "../assets/img/kovaipazhamudirnilayam/12.webp";
import image12 from "../assets/img/kovaipazhamudirnilayam/13.webp";
import image13 from "../assets/img/kovaipazhamudirnilayam/14.webp";
import image14 from "../assets/img/kovaipazhamudirnilayam/15.webp";
import image15 from "../assets/img/kovaipazhamudirnilayam/16.webp";
import image17 from "../assets/img/kovaipazhamudirnilayam/17.webp";
import image18 from "../assets/img/kovaipazhamudirnilayam/18.webp";
import image19 from "../assets/img/kovaipazhamudirnilayam/19.webp";
import image20 from "../assets/img/kovaipazhamudirnilayam/20.webp";
import image21 from "../assets/img/kovaipazhamudirnilayam/21.webp";
import image22 from "../assets/img/kovaipazhamudirnilayam/22.webp";
import image23 from "../assets/img/kovaipazhamudirnilayam/23.webp";
import image24 from "../assets/img/kovaipazhamudirnilayam/24.webp";
import image25 from "../assets/img/kovaipazhamudirnilayam/25.webp";
import image26 from "../assets/img/kovaipazhamudirnilayam/26.webp";
import image27 from "../assets/img/kovaipazhamudirnilayam/27.webp";
import image28 from "../assets/img/kovaipazhamudirnilayam/28.webp";
import image29 from "../assets/img/kovaipazhamudirnilayam/29.webp";
import image30 from "../assets/img/kovaipazhamudirnilayam/30.webp";
import banner1 from "../assets/img/kovaipazhamudirnilayam/banner-1.webp";
import banner2 from "../assets/img/kovaipazhamudirnilayam/banner-2.webp";
import banner3 from "../assets/img/kovaipazhamudirnilayam/banner-3.webp";
import banner4 from "../assets/img/kovaipazhamudirnilayam/banner-4.webp";
import banner5 from "../assets/img/kovaipazhamudirnilayam/banner-5.webp";
import banner6 from "../assets/img/kovaipazhamudirnilayam/banner-6.webp";
import banner7 from "../assets/img/kovaipazhamudirnilayam/banner-7.webp";
import banner8 from "../assets/img/kovaipazhamudirnilayam/banner-8.webp";
import banner9 from "../assets/img/kovaipazhamudirnilayam/banner-9.webp";
import banner10 from "../assets/img/kovaipazhamudirnilayam/banner-10.webp";
import banner11 from "../assets/img/kovaipazhamudirnilayam/banner-11.webp";
import banner12 from "../assets/img/kovaipazhamudirnilayam/banner-12.webp";
import season from "../assets/img/seasonscafe/01.webp";
import kpnsnack from "../assets/img/kpnsnacks/01.webp";
import eauditor from "../assets/img/eauditoroffice/01.webp";
import ourinfinesnack from "../assets/img/ourinfine/ourInfine.webp";
import edurekalearning from "../assets/img/edureka/Edureka.webp";

class Kovaipazhamudirnilayam extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
    return (
      <div>
        <Helmet>
          <title>
            Top Digital Marketing Companies in coimbatore - Bluekodedigital
          </title>
          <meta
            name="description"
            content="Best Digital Marketing in Coimbatore- helps you reach a bigger audience and engage with them effectively."
          />
          <meta
            name="keywords"
            content="paid advertising services,digital marketing professionals,big advertising companies"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <div className="row align-items-center main-image">
                  <div className="col-lg-6">
                    <div className="project-grid">
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={client1}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="col-lg-6 datas-pages">
                    <h1 className="project-description__title work-in-font-h1">
                      Kovai Pazhamudir Nilayam
                    </h1>
                    <p className="project-description__text text-large work-in-font">
                      Kovai Pazhamudir Nilayam, the well-known &amp; trusted
                      brand for fresh from the farm fruits and vegetables to
                      harness the overwhelming customer response through social
                      media. We created a plan that suited their needs,
                      increasing social media presence and creating more online
                      traffic to the website.{" "}
                    </p>
                    <div className="project-info-item">
                      <span className="project-info-item__label">Services</span>
                      <span className="project-info-item__title">
                        Branding, Advertising, Digital Marketing, UI/UX,
                        Creative Production.
                      </span>
                    </div>
                    <ExternalLink
                      href="https://www.kovaipazhamudir.com/"
                      className="project-description__link"
                    >
                      kovaipazhamudir.com{" "}
                      <i className="dorya-icon-arrow-diagonal-up"></i>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Creative Ads
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image28}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image29}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image30}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image1}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image2}
                                className="project__img hover-shrink--zoom"
                                alt="Advertising Agency in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image3}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image4}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image5}
                                className="project__img hover-shrink--zoom"
                                alt="Best Marketing Agencies in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image6}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Agency"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image7}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Companies "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image8}
                                className="project__img hover-shrink--zoom"
                                alt="Corporate Films"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image9}
                                className="project__img hover-shrink--zoom"
                                alt="Online Marketing company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image10}
                                className="project__img hover-shrink--zoom"
                                alt="UI & UX Design Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image11}
                                className="project__img hover-shrink--zoom"
                                alt="Marketing Agency in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image12}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image13}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services "
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image14}
                                className="project__img hover-shrink--zoom"
                                alt="Advertising Agency in Coimbatore"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={image15}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works  work-page-2-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      &nbsp;
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image17}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image18}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image19}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image20}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image21}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image22}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image23}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image24}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image25}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image26}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink same-hei">
                              <img
                                src={image27}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Photography
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner4}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner1}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner2}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner3}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>

                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner5}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner6}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner7}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner8}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner9}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner10}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner11}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={banner12}
                                className="project__img hover-shrink--zoom"
                                alt="Branding & Development Services"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works  work-page-2-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Motion Graphics
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/mOy4bFo3D8k"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/1oQ71EQNl3k"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/EdWbMqpSMJ8"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>

                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url=" https://www.youtube.com/embed/MSDqypF7CiI"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/Qhy_hHGvg90"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/f7oWsElZFA8"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/ISpFo8-KQII"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/vu5h5Tah5rI"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/BlXEgu0hM0o"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/R7bo8GsQH2o"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/7g-ksEfi2Ho"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/bMjy1Qsbbsk"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/Ah7AB61fOVc"
                            width="100%"
                            className="boder-0 height-video"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Creative Production
                    </h1>
                  </div>
                </div>
                <div className="project-grid project-align">
                  <Carousel responsive={responsive}>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/laJZbnOcMV8"
                            width="100%"
                            className="boder-0 height-video-2"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Iframe
                            url="https://www.youtube.com/embed/NqDUzTNf5wU"
                            width="100%"
                            className="boder-0 height-video-2"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
            <section className="section-works work-page-3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Next Projects
                    </h1>
                  </div>
                </div>

                <div className="project-grid project-align">
                  <Carousel responsive={responsive} infinite>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/seasonscafe" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={season}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Company"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/seasonscafe">Seasons Cafe</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/kpnsnacks" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={kpnsnack}
                                className="project__img hover-shrink--zoom"
                                alt="Digital Marketing Services"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/kpnsnacks">KPN Snacks</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/eauditoroffice" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={eauditor}
                                className="project__img hover-shrink--zoom"
                                alt="Best Marketing Agencies in Coimbatore"
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/eauditoroffice">
                                  E-Auditor Office
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/ourinfine" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={ourinfinesnack}
                                className="project__img hover-shrink--zoom"
                                alt="Our Infine "
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/ourinfine">Our Infine</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div>
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <Link to="/edureka" className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={edurekalearning}
                                className="project__img hover-shrink--zoom"
                                alt="Edureka "
                              />
                            </figure>
                          </Link>
                          <div className="project__description-wrap">
                            <div className="project__description">
                              <h3 className="project__title">
                                <Link to="/edureka">Edureka</Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Carousel>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default Kovaipazhamudirnilayam;

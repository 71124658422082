import React, { Component } from "react";

import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";
import team from "../assets/img/team.svg";
import platfrom1 from "../assets/img/platfrom1.svg";
import platfrom2 from "../assets/img/platfrom2.svg";
import rating from "../assets/img/rating.svg";
import employe from "../assets/img/employe.png";
import sprojects from "../assets/img/sprojects.png";
import live from "../assets/img/live.png";
import Who from "../assets/img/whoweare.png";
class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Top Digital Marketing Companies in coimbatore - Bluekodedigital
          </title>
          <meta
            name="description"
            content="Best Digital Marketing in Coimbatore- helps you reach a bigger audience and engage with them effectively."
          />
          <meta
            name="keywords"
            content="paid advertising services,digital marketing professionals,big advertising companies"
          />
        </Helmet>
        <div className="main-wrap">
          <main className="content-wrap">
            <section className="section-works work-page">
              <div className="container">
                <div className="row align-items-center main-image">
                  <div className="col-lg-6">
                    <div className="project-grid">
                      <article className="grid-item project hover-shrink featured branding product">
                        <div className="project__container">
                          <span className="project__url">
                            <figure className="project__img-holder hover-shrink--shrink">
                              <img
                                src={Who}
                                className="project__img hover-shrink--zoom"
                                alt="web design companies"
                              />
                            </figure>
                          </span>
                        </div>
                      </article>
                    </div>
                  </div>
                  <div className="col-lg-6 datas-pages">
                    <h1 className="project-description__title work-in-font-h1">
                      Who we are
                    </h1>
                    <p className="project-description__text text-large work-in-font about-cont ">
                      Bluekode digital is a complete end-to-end digital agency
                      that sorts out all your branding needs using the perfect
                      blend of creativity, strategy, and innovation. With our
                      super-talented team, we design experiences & transform
                      challenges into successful brands, thus making your
                      business stand out from the crowd.
                    </p>

                    <div className="project-info-item about-hint">
                      <h6 className="project-info-item__label">
                        {" "}
                        <img src={employe} alt="web design companies" />
                        25+ Employees
                      </h6>
                      <h6 className="project-info-item__label">
                        <img src={sprojects} alt="web design companies" />
                        100+ Successful projects
                      </h6>
                      <h6 className="project-info-item__label">
                        <img src={live} alt="web design companies" />
                        50+ Live Projects
                      </h6>
                    </div>
                  </div>
                </div>

                {/* WHY CHOOSE US */}
                <div className="row" id="who-we-are">
                  <div className="col-lg-12">
                    <h1 className="project-description__title work-in-font-h1">
                      Why choose us?
                    </h1>
                  </div>
                  <div className="col-lg-3">
                    <img src={platfrom1} alt="web design companies" />

                    <h6>One-stop platform</h6>
                    <p>
                      Right from Brand Strategy to Website Development &
                      everything in between, you can experience all kinds of
                      digital marketing services under one roof.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={platfrom2} alt="web design companies" />

                    <h6>Customized solution</h6>
                    <p>
                      We create and curate customized solutions that exactly
                      suit our client's requirements as well as end users'
                      experience, thus showing a positive graph in their
                      business.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={team} alt="web design companies" />

                    <h6>Team of experts</h6>
                    <p>
                      We have a dedicated team of enthusiastic creators who come
                      up with outstanding ideas and solve complex issues with
                      out-of-the-box thinking.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <img src={rating} alt="web design companies" />
                    <h6>Exceptional Client Service</h6>
                    <p>
                      We always exceed customers’ expectations in terms of value
                      and quality services. Our unique & loyal approach to every
                      client has helped us build long-lasting business
                      relationships.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
export default About;

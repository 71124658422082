import React from "react";
import { useState } from "react";

import Button from "react-bootstrap/Button";
import MyVerticallyCenteredModal from "../../Modal";
function Paidads() {
  const [annually, setAnnually] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="main-wrap">
        <main className="content-wrap">
          <section className="section-works work-page">
            <div className="container ">
              <section id="pricing" className="pos-r">
                <canvas id="canvas"></canvas>
                <div className="containerforpricing">
                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                      <div className="section-title">
                        <div className="title-effect">
                          <div className="bar bar-top"></div>
                          <div className="bar bar-right"></div>
                          <div className="bar bar-bottom"></div>
                          <div className="bar bar-left"></div>
                        </div>
                        <h1 className="project-description__title work-in-font-h1 text-center">
                          Pricing Plan{" "}
                        </h1>
                        <h2 className="title">
                          Paid Ads Strategy Packages in India
                        </h2>
                        <p>
                          Paid ads are an essential tool in any digital
                          marketing campaign. Choose customized paid ads
                          packages from below.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="toggle lg:mb-10">
                    <label className="name">Monthly</label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked={annually}
                      onChange={() => setAnnually(!annually)}
                    />
                    <label htmlFor="checkbox" className="label">
                      <div className="ball"></div>
                    </label>
                    <label className="name">Quarterly</label>
                    <div id="badge">
                      <h6 className="vc">
                        Offer upto <span className="">&#8377;</span>10,000
                      </h6>
                    </div>
                  </div>
                  <div className="row pzero">
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table first-table">
                        <div className="price-header">
                          <h3 className="price-title">Starter</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                10000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                30000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                27000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">Paid Ads</h6>
                              {!annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget<span className="blue">2000</span>
                                </li>
                              )}
                              {annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget<span className="blue">7000</span>
                                </li>
                              )}
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Campaigns<span className="blue">3</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Groups<span className="blue">2</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Copies
                                <span className="blue"> Maximum 6</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No. of Keywords<span className="blue">50</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Search Ads
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Display Ads
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Conversion Tracking
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-13"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-13"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      PPC Campaign Management
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor Analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Keyword Optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Ad Copy Optimization
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Value Adds
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      ROI Analysis
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Weekly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                  </ul>
                                </div>

                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table active">
                        <div className="price-header">
                          <div className="snow-container">
                            <h6 className="popular">POPULAR</h6>
                          </div>
                          <h3 className="price-title white">Professional</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                15000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut proff ">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                45000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate white">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                40000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">Paid Ads</h6>
                              {!annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget<span>&nbsp;3000</span>
                                </li>
                              )}
                              {annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget<span>&nbsp;10000</span>
                                </li>
                              )}
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Campaigns<span>&nbsp;5</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Groups<span>&nbsp;3</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Copies<span>&nbsp;Maximum 8</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No. of Keywords<span>&nbsp;70</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Search Ads
                              </li>

                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Display Ads
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Conversion Tracking
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-14"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger "
                              htmlFor="faq-drawer-14"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Video Ads
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Remarketing List
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      PPC Campaign Management
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor Analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Keyword Optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Ad Copy Optimization
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Value Adds
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      ROI Analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Landing Page Optimization
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Weekly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                  </ul>
                                </div>
                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="price-table">
                        <div className="price-header">
                          <h3 className="price-title">Advanced</h3>
                          {!annually && (
                            <>
                              <br />
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                20000
                              </h3>
                            </>
                          )}
                          {annually && (
                            <>
                              <h4 className=" dollarcut">
                                <span className="dollars ">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                60000
                              </h4>
                              <h3 className="flex items-center justify-center text-slate-700 text-5xl rate">
                                <span className="dollar">
                                  {" "}
                                  <span className="">&#8377;</span>{" "}
                                </span>{" "}
                                50000
                              </h3>
                            </>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          onClick={() => setModalShow(true)}
                          className="btn btn-theme my-4"
                          data-text="Get Started"
                        >
                          <span>G</span>
                          <span>e</span>
                          <span>t</span>
                          <span>&nbsp;</span>
                          <span>S</span>
                          <span>t</span>
                          <span>a</span>
                          <span>r</span>
                          <span>t</span>
                          <span>e</span>
                          <span>d</span>
                        </Button>

                        <MyVerticallyCenteredModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <div className="price-list pzero table-section">
                          <div className="first-box">
                            <ul className="list-unstyled text-left">
                              <h6 className="t-head color-blue">Paid Ads</h6>
                              {!annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget
                                  <span className="blue bold">5000</span>
                                </li>
                              )}
                              {annually && (
                                <li>
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                  Ads Budget<span className="blue">17000</span>
                                </li>
                              )}
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Campaigns<span className="blue">8</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Groups<span className="blue">4</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Ad Copies
                                <span className="blue">Maximum 10</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                No. of Keywords<span className="blue">100</span>
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Search Ads
                              </li>

                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Display Ads
                              </li>
                              <li>
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                                Conversion Tracking
                              </li>
                            </ul>
                          </div>
                          <br />
                          <div className="faq-drawer">
                            <input
                              className="faq-drawer__trigger for-mobile-trigger"
                              id="faq-drawer-15"
                              type="checkbox"
                            />
                            <label
                              className="faq-drawer__title for-mobile-trigger"
                              htmlFor="faq-drawer-15"
                            >
                              Show Features
                            </label>
                            <div className="faq-drawer__content-wrapper">
                              <div className="faq-drawer__content">
                                <div className="first-box">
                                  <ul className="list-unstyled text-left">
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Video Ads
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Remarketing List
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Shopping Ads
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      App Promotion Ads
                                    </li>
                                  </ul>
                                </div>
                                <div className="second-box">
                                  <ul className="list-unstyled text-left">
                                    <h6 className="t-head color-blue">
                                      PPC Campaign Management
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Competitor Analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Keyword Optimization
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Ad Copy Optimization
                                    </li>
                                  </ul>
                                </div>
                                <div className="third-box">
                                  <ul className="list-unstyled text-left ">
                                    <h6 className="t-head color-blue">
                                      Value Adds
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      ROI Analysis
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Landing Page Optimization
                                    </li>
                                  </ul>
                                </div>
                                <div className="fourth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Reports
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Weekly
                                    </li>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Monthly
                                    </li>
                                  </ul>
                                </div>

                                <div className="fifth-box">
                                  <ul className="list-unstyled text-left bold">
                                    <h6 className="t-head color-blue">
                                      Support
                                    </h6>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      E-mail
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Chat
                                    </li>
                                    <li>
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      Call
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="container text-center">
              <p>
                Please note that GST will be charged in addition to the prices
                mentioned.
              </p>
            </div>
            <div className="container faq">
              <div className="row">
                <div className="col-md-12">
                  <h2>FAQ</h2>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer">
                      What is a pay-per-click campaign?{" "}
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          PPC is the most efficient method of reaching a
                          relevant target audience for your site. It is the only
                          way that advertisers have to pay the publisher when
                          someone clicks their advertisements at the end of the
                          page, which will arrive at the advertisers landing
                          page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-2"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-2">
                      How to run an effective campaign for your company
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <ul>
                          <li>
                            <strong>Determine your purpose :</strong> First, you
                            must consider the reason you are creating this ad
                            campaign, and what do you expect from this campaign,
                            and the best way and location you'll need to put
                            your ads, or which advertising company to consider
                            at the end of the day, "well begun is half
                            completed."
                          </li>
                          <li>
                            <strong>
                              Choose your daily or budget-friendly expenditure :
                            </strong>{" "}
                            Is anyone taking part in the discussion or sharing
                            posts?
                          </li>
                          <li>
                            <strong>The frequency of mentions :</strong>
                            Following that, you'll be required to determine your
                            daily budget since you must pay your publishers on a
                            regular basis , or whatever other options are
                            available.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-3"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-3">
                      Why use pay-per-click advertising?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It is recommended to use pay per click ads to increase
                          the traffic to your website. PPC could have a
                          significant and positive effect on your brand and
                          business. PPC advertising:
                        </p>
                        <ul>
                          <li>Provide a quick entry into the SERPs .</li>
                          <li>The results are simple to track and track.</li>
                          <li>
                            It is compatible when combined with other channels
                            of marketing.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-4"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-4">
                      How Long Does It Take to Build a Campaign?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It only takes 5 minutes to create an effective
                          campaign and start increasing traffic to your site.
                        </p>
                        <p>
                          But the issue lies in that, for a great success in
                          your business it is important to remember that at the
                          beginning stage of your campaign , you must wait for
                          the first week to go by then check and adjust the
                          campaign to get more effective results. After
                          following these guidelines for a month, you'll be able
                          to observe a tremendous increase in visitors to your
                          website.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-5"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-5">
                      Do You Work on My Google Ads Account?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Yes, we manage your account. You can create your
                          advertising campaign on Google, Facebook or Bing.But
                          to get a lot of visitors to your website It will take
                          about an entire month of dedication and study.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-6"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-6">
                      Can you guarantee results?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          We can assure you that you'll get the most effective
                          results from your advertising campaign. We're here to
                          assist you achieve the highest results from your ads
                          while we design the content, managing and optimizing
                          and optimizing your Google Ad words, Bing Ads, and
                          Facebook Ads.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-7"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-7">
                      How long will it take to see results?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          It's true that PPC could boost brand's awareness and
                          boost visibility starting from the beginning. However,
                          a successful campaign may require months of thoughtful
                          and careful optimization before the true benefits for
                          your PPC campaign are apparent.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="faq-drawer">
                    <input
                      className="faq-drawer__trigger"
                      id="faq-drawer-8"
                      type="checkbox"
                    />
                    <label className="faq-drawer__title" htmlFor="faq-drawer-8">
                      Do Paid Ads Work?
                    </label>
                    <div className="faq-drawer__content-wrapper">
                      <div className="faq-drawer__content">
                        <p>
                          Now, let's tackle the million-dollar question: Can
                          paid ads actually work? The answer is yes absolutely.
                          If you've created an appealing advertisement, make the
                          bid in a competitive manner, and select your target
                          parameters with care, you'll begin to see more clicks
                          after the campaign is live.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
export default Paidads;
<div className="main-wrap">
  <main className="content-wrap">
    <section className="section-works work-page">
      <div className="container"></div>
    </section>
  </main>
</div>;

import React, { Component } from "react";
class Aboutus extends Component {
  render() {
    return (
      <div>
        <section className="home-section-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="animate">
                  <h3 className="intro__subtitle">
                    We connect brands with people
                  </h3>
                </div>
                <div className="animate">
                  <p className="intro__text text-large intro-content">
                    Bluekode Digital is a results-driven creative digital agency
                    in Coimbatore, that focuses on giving your brand the voice &
                    identity it truly deserves. With an in-depth understanding
                    of businesses and needs, we deliver tailor-made, futuristic
                    solutions that initiate curiosity and excitement among the
                    audience.{" "}
                  </p>
                  <p className="intro__text text-large intro-content">
                    We are a people-centric branding firm backed by
                    multidisciplinary experts who strive hard to promote/ create
                    brands through Brand Strategy, Digital Advertising, Content
                    Creation, SEO, Graphic Design, and Website & App
                    Development.
                  </p>
                  <p className="intro__text text-large intro-content">
                    Every business has a story & we can tell your story the best
                    way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Aboutus;
